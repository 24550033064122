/*https://res.cloudinary.com/higher-design/image/upload/v1551630385/categories/*/

export default {
  index: {
    "app": {
      name: "app",
      title: "Apps",
      marginRight: -6,
      imageWidth: 40,
    },
    "video": {
      name: "video",
      title: "Video",
      imageWidth: 40,
    },
    "hardware": {
      name: "hardware",
      title: "Hardware",
      margins: 4,
      imageWidth: 40,
    },
    "bluechess": {
      name: "bluechess",
      title: "Teams",
      margins: 4,
      imageWidth: 40,
    },
    "web": {
      name: "web",
      title: "Web",
      imageWidth: 40,
    },
    "brand": {
      name: "brand",
      title: "Brand",
      imageWidth: 40,
    },
    "patent": {
      name: "patent",
      title: "Patents",
      imageWidth: 31,
    },
    "content": {
      name: "content",
      title: "Content",
      imageWidth: 40,
    },
    "cloud": {
      name: "cloud",
      title: "Cloud",
      imageWidth: 60,
    },
    "web-cloud": {
      name: "web-cloud",
      title: "Web, Cloud",
      imageWidth: 80,
    },
    "app-cloud": {
      name: "app-cloud",
      title: "App, Cloud",
      imageWidth: 80,
    },
    "app-web": {
      name: "app-web",
      title: "App, Web",
      imageWidth: 75,
    },
  },
  sequence: [ "app", "patent", "hardware", "web", "cloud" ]
};

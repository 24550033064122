export default {
  home: {
    index: {
      top: {
        name: "top",
        title: "HIGHER"
      },
      portfolio: {
        name: "portfolio",
        title: "PORTFOLIO"
      },
      people: {
        name: "people",
        title: "PEOPLE",
      },
      story: {
        name: "story",
        title: "STORY",
      },
      approach: {
        name: "approach",
        title: "APPROACH",
      },
      cv: {
        name: "cv",
        title: "CV",
      },
    },
    sequence: ["top", "portfolio", "story", "approach", "cv"] /*people*/
  }

}

import React from 'react';
import { Animated, Easing, Platform, StyleSheet, View, TouchableWithoutFeedback } from 'react-native';
/*import { Haptic } from 'expo';*/

export default class Hamburger extends React.Component {

  constructor(props) {
    super(props);
    this.state = { animation: new Animated.Value(props.active ? 100 : 0)};
  }

  // Set initla state
  componentDidMount() {
    //this.state = { rotation: Animated.value(this.props.rotation || 0) };
  }

  // Auto scroll to bottom after extending
  componentDidUpdate(prevProps, prevState) {
    if ( this.props.active !== prevProps.active ) {
      Animated.timing(
           this.state.animation,
           {
             toValue: this.props.active ? 100 : 0,
             duration: 300,
             easing: Easing.back()
           }
         ).start();
    }
  }

  render() {

    // Compute the translation
    const topTranslation = this.state.animation.interpolate({
      inputRange: [0, 100],
      outputRange: ["0%", "40%"],
    });

    // Compute degrees
    const topRotation = this.state.animation.interpolate({
      inputRange: [0, 100],
      outputRange: ['0deg', '45deg'],
    });

    // Compute the translation
    const bottomTranslation = this.state.animation.interpolate({
      inputRange: [0, 100],
      outputRange: ["80%", "40%"],
    });

    // Compute degrees
    const bottomRotation = this.state.animation.interpolate({
      inputRange: [0, 100],
      outputRange: ['0deg', '-45deg'],
    });

    // Compute the middle height
    const middleWidth = this.state.animation.interpolate({
      inputRange: [0, 100],
      outputRange: ["100%", "0%"],
    });

    // Compute the translation
    const middleTranslation = this.state.animation.interpolate({
      inputRange: [0, 100],
      outputRange: ["0%", "50%"],
    });


    return (

      <TouchableWithoutFeedback
        onPressIn={ () => {
          this.setState({ pressed: true });
        }}
        onPressOut= { () => {
          //if ( Platform.OS === 'ios' ) { Haptic.impact(Haptic.ImpactFeedbackStyle.Light); }
          this.setState({ pressed: false });
        }}
        onPress={ () => {
            if ( this.props.onPress ) {
              this.props.onPress();
            }
          }
        }>
        <View style={{ position: "absolute", zIndex: 1000, top: 0, left: 0, width: this.props.active ? 60 : "100%", height: 50 }}>
          <View style={{ ...this.props.style, width: this.props.width, height: this.props.height }}>
            <Animated.View style={{
                position: "absolute",
                width: "100%",
                height: "20%",
                top: topTranslation,
                left: 0,
                backgroundColor: this.props.color || "black",
                transform: [{ rotate: topRotation }]
              }}/>
            <Animated.View style={{
                position: "absolute",
                width: middleWidth,
                height: "20%",
                top: "40%",
                left: middleTranslation,
                backgroundColor: this.props.color || "black",
              }}/>
            <Animated.View style={{
                position: "absolute",
                width: "100%",
                height: "20%",
                top: bottomTranslation,
                left: 0,
                backgroundColor: this.props.color || "black",
                transform: [{ rotate: bottomRotation }]
              }}/>
          </View>
          </View>
      </TouchableWithoutFeedback>
    );
  }
}

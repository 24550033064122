//https://res.cloudinary.com/higher-design/image/upload/v1552703321/people/pete.jpg
import React from 'react';
import {
  View,
  Text,
  FlatList,
  Dimensions,
  Platform,
  StyleSheet,
} from 'react-native';
import PeopleStrip from '../../../components/strips/PeopleStrip';
import PEOPLE from '../../../constants/people';

export default class PeopleSection extends React.Component {

  // Track the window size
  state = Dimensions.get("window");

  // Jump to the given person
  onPersonPress(person) {
    if ( Platform.OS == "web" ) {
      window.open(person.url, "_blank");
    }
    else {
      if ( this.props.history ) {
        this.props.history.push(person.url);
      }
    }
  }

  // Update our dimensions now
  updateDimensions() {
    this.setState(Dimensions.get('window'));
  }

  componentWillMount() {
    if ( Platform.OS == "web" ) {
      window.addEventListener("resize", () => { this.updateDimensions() });
    }
    else {
      Dimensions.addEventListener("change", () => { this.updateDimensions() });
    }
  }

  componentWillUnmount() {
    if ( Platform.OS == "web" ) {
      window.removeEventListener("resize", () => { this.updateDimensions() });
    }
    else {
      Dimensions.removeEventListener("change", () => { this.updateDimensions() });
    }
  }

  render() {

    // Is it the narrow version
    const narrow = this.state.width < 950;

    // Show a header?
    let header = <View/>;
    if ( this.props.showHeader ) {
      header = <Text style={{ paddingTop: 15, paddingBottom: 15, textAlign: "center", fontSize: 28, fontWeight: "800", fontFamily: 'System'}}>PEOPLE</Text>;
    }

    // Optional line
    let line = <View/>;
    if ( this.props.line ) {
      line = <View style={{ height: 1, marginTop: 10, backgroundColor: "#DBDBDB"}}/>;
    }

    // Show our section
    return (
      <View style={{ backgroundColor: "#ffffff", textAlign: "center", paddingBottom: 10 }}>
        {header}
        <Text style={{ marginTop: 0, marginBottom: 10, fontSize: 16, fontWeight: "400", fontFamily: 'System' }}>{this.props.description}</Text>
        <View style={styles.people}>
          <PeopleStrip people={PEOPLE.sequence} onPersonPress={(person) => { this.onPersonPress(person); }}/>
        </View>
        <View style={{ height: 10 }}/>
        {line}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: "white",
    shadowColor: '#000',
    borderRadius:10,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 20,
    elevation: 5,
    paddingTop: 15,
    paddingBottom: 10,
    marginLeft: 20,
    marginRight: 20,
    paddingLeft: 25,
    paddingRight: 35,
  },
  cardContent: {
    width: "100%",
    height: "100%",
  },
  wideList: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: 1200
  }
});

import React, { Component, PropTypes } from 'react'
import { Animated, View, Image, StyleSheet } from 'react-native'

export default class HigherImage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imageOpacity: new Animated.Value(0),
      thumbnailOpacity: new Animated.Value(1),
    }
  }

  onLoadThumbnail() {
    if ( this.props.onLoadThumbnail ) {
      this.props.onLoadThumbnail()
    }
  }

  onLoadImage() {
    Animated.timing(this.state.imageOpacity, {
      toValue: 1,
      duration: this.props.imageFadeDuration,
      useNativeDriver: true,
    }).start()
    if ( this.props.onLoadImage ) {
      this.props.onLoadImage()
    }
  }

  render() {

    let fullImage;
    if ( this.props.shouldLoadImage ) {
      console.log('Loading full image');
      fullImage = <Animated.Image
        resizeMode="cover"
        style={[styles.image, { opacity: this.state.imageOpacity }, this.props.style]}
        source={this.props.imageSource}
        onLoad={() => this.onLoadImage()}
      />;
    }

    return (
      <View style={this.props.style}>
        <Animated.Image
          resizeMode="cover"
          style={[styles.image, { opacity: this.state.thumbnailOpacity }, this.props.style]}
          source={this.props.thumbnailSource}
          onLoad={() => this.onLoadThumbnail()}
        />
        {fullImage}
      </View>
    )
  }
}

 const styles = StyleSheet.create({
   image: {
     position: 'absolute',
     top: 0,
     bottom: 0,
     left: 0,
     right: 0,
   },
 })

/*ProgressiveImage.propTypes = {
  placeHolderColor: PropTypes.string,
  placeHolderSource: PropTypes.number,
  imageSource: PropTypes.object.isRequired,
  imageFadeDuration: PropTypes.number.isRequired,
  onLoadThumbnail: PropTypes.func.isRequired,
  onLoadImage: PropTypes.func.isRequired,
  thumbnailSource: PropTypes.object.isRequired,
  thumbnailFadeDuration: PropTypes.number.isRequired,
  thumbnailBlurRadius: PropTypes.number,
}*/

HigherImage.defaultProps = {
  thumbnailFadeDuration: 250,
  imageFadeDuration: 250,
  onLoadThumbnail: Function.prototype,
  onLoadImage: Function.prototype,
}

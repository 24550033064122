export default {
  "blue": {
    name: "blue",
    image: "blue/blue-microphone-zoom",
    theme: "light",
    aspectRatio: 0.55,
    title: "Blue R&D",
    subtitle: "Hardware",
    categories: ["hardware"],
    searchOrder: 5,
    description: "Performed research and development and designed new portable hardware products for Blue, a leader in high-end microphones and headphones, prior to their acquisition by Logitech.",
    content: [
      { description: true },
    ],
  },
};

//https://res.cloudinary.com/higher-design/image/upload/v1552703321/people/pete.jpg
import React from 'react';
import {
  View,
  Text,
  FlatList,
  Dimensions,
  Platform,
  Image,
  StyleSheet,
} from 'react-native';
import HigherGradient from '../../../components/utility/HigherGradient'

//import { LinearGradient } from 'expo';

import PEOPLE from '../../../constants/people';

export default class TextSection extends React.Component {

  // Track the window size
  state = Dimensions.get("window");

  onJobPress(job) {
  }

  // Update our dimensions now
  updateDimensions() {
    this.setState(Dimensions.get('window'));
  }

  componentWillMount() {
    if ( Platform.OS == "web" ) {
      window.addEventListener("resize", () => { this.updateDimensions() });
    }
    else {
      Dimensions.addEventListener("change", () => { this.updateDimensions() });
    }
  }

  componentWillUnmount() {
    if ( Platform.OS == "web" ) {
      window.removeEventListener("resize", () => { this.updateDimensions() });
    }
    else {
      Dimensions.removeEventListener("change", () => { this.updateDimensions() });
    }
  }

  render() {

    // Lookup the person
    const person = PEOPLE.index[this.props.person] || {};
    const about = person.about || {};

    // Is it the narrow version
    const isNarrow = this.state.width < 950;
    const isReallyNarrow = this.state.width < 400;

    // Show a header?
    let header = <View/>;
    if ( this.props.showHeader ) {
      header = <Text style={{ paddingTop: 15, paddingBottom: 15, textAlign: "center", fontSize: 28, fontWeight: "800", fontFamily: 'System'}}>{this.props.title.toUpperCase()}</Text>;
    }

    // Optional line
    let line = <View/>;
    if ( this.props.line ) {
      line = <View style={{ height: 1, marginTop: 10, backgroundColor: "#DBDBDB"}}/>;
    }

    // Is there an illustration on the left or right
    let illustrationOnLeft = <View/>;
    let illustrationOnRight = <View/>;
    let illustrationOnBottom = <View/>;
    if ( this.props.illustration ) {

      if ( !isNarrow ) {

        // Get the image we're going to use
        const illustrationImage = <Image style={{ width: this.props.illustration.width || 400,
                                                  height: this.props.illustration.height || 400 }}
                                         source={{ uri: `https://res.cloudinary.com/higher-design/image/upload/c_mpad,h_1024,w_1024/illustrations/${this.props.illustration.image}` }} />

        // Is it on the left or right side?
        if ( this.props.illustration.side == "left") {
          illustrationOnLeft = <View style={styles.illustrationOnLeft}>{illustrationImage}</View>;
        }
        else if ( this.props.illustration.side == "right") {
          illustrationOnRight = <View style={styles.illustrationOnRight}>{illustrationImage}</View>;
        }
      }
      else {

        // Get the image we're going to use
        const illustrationImage = <Image style={{ width: this.props.illustration.width * 0.8 || 320,
                                                  height: this.props.illustration.height * 0.8 || 320 }}
                                         source={{ uri: `https://res.cloudinary.com/higher-design/image/upload/c_mpad,h_1024,w_1024/illustrations/${this.props.illustration.image}` }} />
        illustrationOnBottom = <View style={styles.illustrationOnBottom}>{illustrationImage}</View>;
      }
    }

    // Show our section
    return (
      <View style={styles.section}>
        <HigherGradient
           direction="to bottom"
           from={this.props.fromColor || "#f4f4f4"}
           to={this.props.toColor || "#ffffff"}>
           <View style={styles.textColumn}>
            <View style={styles.textRow}>
              {illustrationOnLeft}
              <View style={styles.text}>
                {header}
                <View style={[styles.description, isReallyNarrow && styles.descriptionReallyNarrow]}>
                  {this.props.paragraphs.map((paragraph, index) => {
                    return <Text key={index} style={styles.paragraph}>{paragraph}</Text>
                  })}
                </View>
              </View>
              {illustrationOnRight}
            </View>
            {illustrationOnBottom}
          </View>
          {line}
          <View style={{ height: '10px' }}/>
        </HigherGradient>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  section:{ 
    backgroundColor: "#ffffff", 
    width: "100%",
  },
  textColumn:{
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
  },
  textRow:{
    display: "flex",
    flexDirection: "row",
  },
  description: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 20,
    marginRight: 20,
    maxWidth: 370,
    textAlign: "left",
  },
  descriptionReallyNarrow: {
    maxWidth: 325,
  },
  paragraph: {
    fontSize: 16,
    fontWeight: "400",
    fontFamily: 'System',
    marginBottom: 15,
  },
  illustrationOnLeft:{
    alignSelf: "flex-end",
    marginLeft: 20,
    marginRight: 20,
  },
  illustrationOnRight:{
    alignSelf: "flex-start",
    marginLeft: 20,
    marginRight: 20,
  },
  illustrationOnBottom:{
    alignSelf: "flex-start",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "0 auto",
    width: "100%",
    marginBottom: 10,
    marginTop: -10,
  },
});

import * as ACTIONS from './actionTypes.js'

var debounceTimeout = null;

export function chooseMenu(menu, {debounce=0}={}) {
  return (dispatch)=>{
    // Check to see if we need to debounce menu
    if (debounce>0) {
      dispatch(disableMenu(menu));
      debounceTimeout = setTimeout(()=>{
        dispatch(enableMenu(menu));
      },debounce);
    } else {
      dispatch({ type: ACTIONS.CHOOSE_MENU, menu: menu });
    }
  };
}

export function enableMenu(menu, {debounce=0}={}) {
  if (debounceTimeout) {
    clearTimeout(debounceTimeout);
    debounceTimeout = null;
  }
  return { type: ACTIONS.ENABLE_MENU, menu: {...menu, disabled: false} };
}

export function disableMenu(menu, {debounce=0}={}) {
  if (debounceTimeout) {
    clearTimeout(debounceTimeout);
    debounceTimeout = null;
  }
  return { type: ACTIONS.DISABLE_MENU, menu: {...menu, disabled: true} };
}

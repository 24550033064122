import React from 'react';
import { Animated, Easing, Platform, StyleSheet, View, Text, TouchableOpacity, TouchableWithoutFeedback } from 'react-native';
import CategoryStrip from '../strips/CategoryStrip';
import CategoriesHeader from '../headers/CategoriesHeader';
import TextHeader from '../headers/TextHeader';
/*import { Haptic } from 'expo';*/

export default class SectionHeader extends React.Component {

  // Render headers on mobile
  renderMobileHeader() {

    // Handle category presses
    const onTitlePress = () => {
      if ( this.props.onTitlePress ) {
        this.props.onTitlePress(this.props.title);
      }
    };

    // Handle category presses
    const onCategoryPress = category => {
      if ( this.props.onCategoryPress ) {
        this.props.onCategoryPress(category);
      }
    };

    switch (this.props.title) {
      case "Categories":
        return <CategoriesHeader scroll={this.props.scroll}
                                 personName={this.props.personName}
                                 description={this.props.description}
                                 categories={this.props.categories}
                                 touchable={this.props.touchable}
                                 onTitlePress={onTitlePress}
                                 onCategoryPress={onCategoryPress}/>;
      case "Skills":
      case "Impact":
        return <TextHeader line={this.props.showLine} touchable={this.props.touchable} title={this.props.title} onPress={onTitlePress}/>
      case "About":
        return <TextHeader line={this.props.showLine} touchable={this.props.touchable} background="#ffffff" title={this.props.title} onPress={onTitlePress}/>
      case "People":
        return <TextHeader line={this.props.showLine} touchable={this.props.touchable} background="#ffffff" title={this.props.title} onPress={onTitlePress}/>
      case "Story":
      case "Approach":
        return <TextHeader line={this.props.showLine} touchable={this.props.touchable} background="#ffffff" title={this.props.title} onPress={onTitlePress}/>
      case "CV":
      case "Talk":
        return <TextHeader title={this.props.title} touchable={this.props.touchable} background="#ffffff" onPress={onTitlePress}/>
      default:
        return <View style={{ height: 0 }}></View>
    }
  }

  // Render headers on desktop
  renderDesktopHeader() {

    // Handle category presses
    const onCategoryPress = category => {
      if ( this.props.onCategoryPress ) {
        this.props.onCategoryPress(category);
      }
    };

    switch (this.props.title) {
      case "Categories":
        return <View><CategoryStrip line categories={this.props.categories} onCategoryPress={onCategoryPress}/></View>
      default:
        return <View style={{ height: 0 }}></View>
    }

  }

  render() {
    if ( this.props.mobile ) {
      return this.renderMobileHeader();
    }
    else {
      return this.renderDesktopHeader();
    }
  }
}

// src/Home.js
import React from 'react';
import {
  View,
  Text,
  Pressable,
  Animated
} from 'react-native';

class CloseButton extends React.Component {

  handlePress() {
    if ( this.props.onClose ) {
      this.props.onClose();
    }
  }

  render() {

    // Are we dark or light?
    let backgroundColor = this.props.light ? "rgba(50,50,50,0.7)" : "rgba(200,200,200,0.7)";
    let foregroundColor = this.props.light ? "#BBBBBB" : "black";

    // Calculate a few things
    const sizePercentage = this.props.size ? `${this.props.size * 100.0}%` : "56%";
    const thicknessPercentage = this.props.thickness ? `${this.props.thickness * 100.0}%` : "18%";
    const paddingPercentage = this.props.thickness ? `${((1.0 - this.props.thickness) / 2) * 100.0}%` : "41%";

    return (
      <Pressable onPressIn={ () => { }}
                                onPressOut= { () => { }}
                                onPress={ () => { this.handlePress(); } }>
        <View style={{ width: 56, height: 56, cursor: "pointer" }}>
          <View style={{ backgroundColor: backgroundColor, marginTop: 15, marginLeft: 15, width: 26, height: 26, borderRadius: 20 }}>
            <View style= {{ marginTop: 4, marginLeft: 4, width: 18, height: 18 }}>
              <View style={{
                      width: sizePercentage,
                      height: sizePercentage,
                      transform: [{ rotate: '45deg' }]
                    }}>
                      <View style={{
                        position: "absolute",
                        width: thicknessPercentage,
                        height: "100%",
                        top: 0,
                        left: paddingPercentage,
                        backgroundColor: foregroundColor
                      }}/>
                      <View style={{
                        position: "absolute",
                        width: "100%",
                        height: thicknessPercentage,
                        top: paddingPercentage,
                        left: 0,
                        backgroundColor: foregroundColor
                      }}/>
                </View>
              </View>
          </View>
        </View>
      </Pressable>
    );
  }
};
export default CloseButton;

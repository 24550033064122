import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

class SkillItem extends React.Component {

  render() {

    // Return it now
    return (
      <Text style={{ textAlign: "left",
                     fontSize: 16,
                     fontWeight: "400",
                     fontFamily: 'System',
                     marginTop: 7 }}>
        {this.props.bullet}
      </Text>
    );
  }
};

export default SkillItem;

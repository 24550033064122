// src/Home.js
import React from 'react';
import {
  View,
  Text,
  Easing,
  Pressable,
  Animated
} from 'react-native';

class ColorButton extends React.Component {

  // Used for animations on press
  state = {
    pressed: false,
    scale: new Animated.Value(1),
  }

  handlePress() {
    if ( this.props.onPress ) {
      this.props.onPress();
    }
  }

  render() {

    return (
      <Pressable style={{ flex: 1, cursor: "pointer" }}
             onPressIn={ () => {
                //if ( Platform.OS === 'ios' ) { Haptic.selection(); }
                //Haptic.impact(Haptic.ImpactStyles.Light);
                this.setState({ pressed: true });
                Animated.timing(                  // Animate over time
                     this.state.scale,            // The animated value to drive
                     {
                       toValue: this.props.downScale || 0.98,               // Animate to size 18
                       duration: 50,              // Make it take a while
                     }
                   ).start();
              }}
              onPressOut= { () => {
                //if ( Platform.OS === 'ios' ) { Haptic.impact(Haptic.ImpactFeedbackStyle.Light); }
                this.setState({ pressed: false });
                Animated.timing(                   // Animate over time
                     this.state.scale,              // The animated value to drive
                     {
                       toValue: 1,                 // Animate back to small size
                       duration: 500,              // Make it take a while
                       easing: Easing.back(),
                     }
                   ).start();
              }}
              onPress={ () => { this.handlePress(); } }>
        <Animated.View style={{ backgroundColor: this.props.backgroundColor || "black",
                       width: this.props.width,
                       height: this.props.height || 50,
                       borderRadius: this.props.borderRadius || 5,
                       shadowOffset: { width: 0, height: 1 },
                       shadowOpacity: 0.1,
                       shadowRadius: 20,
                       elevation: 5,
                       flex: 1,
                       transform: [{ scale: this.state.scale}] }}>
          <Text style={{
            color: this.props.textColor || "white",
            fontSize: this.props.fontSize || 16,
            fontWeight: "500",
            fontFamily: "System",
            flex: '1',
            alignSelf: 'center',
            lineHeight: this.props.height || 50,
            marginTop: this.props.textTopMargin,
            marginLeft: 15,
            marginRight: 15,
          }}>{this.props.text}</Text>
        </Animated.View>
      </Pressable>
    );
  }
};
export default ColorButton;

/*https://res.cloudinary.com/higher-design/image/upload/v1551630385/categories/*/

import React from 'react';
import TextBladeProject from '../components/projects/TextBladeProject';

import UPGIFT from "./projects/upgift"
import WAYTOOLS from "./projects/waytools"
import NEXTENGINE from "./projects/nextengine"
import PERSONAL from "./projects/personal"
import STORYJAR from "./projects/storyjar"
import MAGBAK from "./projects/magbak"
import TESLA from "./projects/tesla"
import DAQRI from "./projects/daqri"
import BLUE from "./projects/blue"
import FUTURE from "./projects/future"
import TWELVE from "./projects/twelve"
import PICNIC from "./projects/picnic"

export default {
    index: {
      ...UPGIFT,
      ...WAYTOOLS,
      ...NEXTENGINE,
      ...PERSONAL,
      ...STORYJAR,
      ...MAGBAK,
      ...TESLA,
      ...DAQRI,
      ...BLUE,
      ...FUTURE,
      ...PICNIC,
      ...TWELVE,
    },
    rows: [{ cards: ["textblade-keyboard", "textblade-app", "textblade-order-system", "textblade-patent", "keyswitch-patent", "separable-keyboard-patent", "textblade-design-patent", "textblade-cursor-patent", "textblade-modkeys-patent", "textblade-international-layouts", "fingerbot", "forcebot", "textblade-website", "remote-scopes",  "realtime-device-sync", "textblade-video", "hands-on-video", "universal-keyboard-video", "multimap-video", "android-video", ] },
           { cards: ["nextengine-scanner", "scanstudio", "nextengine-comparo", "handheld-scanner-patent", "photo-chat-patent", "integrated-capture-patent", "shape-search-patent", "nextengine-website", "nextengine-order-system", "nextwiki", "rapidworks", ]},
           { cards: ["magbak-case", "magbak-wallet", "magbak-charger", "magbak-patent-self-align", "magbak-patent-low-profile"]},
           { cards: ["upgift-app", "upgift-website", "upgift-video", "upgift-payments", "upgift-social-network", "upgift-brand"]},
           { cards: ["model3", "blue", "daqri", "teamfolio"]},
          ]
}

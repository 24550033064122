// src/Home.js
import React from 'react';
import {
  View,
  Text,
  FlatList,
} from 'react-native';
import MenuItem from '../../components/items/MenuItem';

class MenuStrip extends React.Component {

/*
  // Used for animations on press
  state = {
    animation: new Animated.Value(0)
  }

  componentDidMount() {
    if ( !this.props.selectedCategoryName || this.props.selectedCategoryName === this.props.category.name ) {
      this.state.saturation.setValue(1);
    }
    else {
      this.state.saturation.setValue(0);
    }

  }

  // Auto scroll to bottom after extending
  componentDidUpdate(prevProps, prevState) {
    if ( this.props.selectedItemName !== prevProps.selectedItemName ) {

      // Figure out if we're selected
      const isColorful = this.props.selectedItemName;

      // Are we selected or not selected
      if ( isColorful ) {
        Animated.timing(                     // Animate over time
             this.state.saturation,            // The animated value to drive
             {
               toValue: 1,                   // Animate to full color
               duration: 300,                // Make it take a while
             }
           ).start();
      }
      else {
        Animated.timing(                     // Animate over time
             this.state.saturation,            // The animated value to drive
             {
               toValue: 0,                     // Animate to gray
               duration: 300,                // Make it take a while
             }
           ).start();
      }
    }
  }

*/
  render() {

    // Handle category presses
    const onMenuPress = menuName => {
      if (this.props.onMenuPress) {
        this.props.onMenuPress(menuName);
      }
    };

    // Find the active menu item, and if there is one,
    // then it may change the background color
    let background = "#ffffff";
    if ( this.props.selectedItemName ) {
      const selectedItem = this.props.menu.index[this.props.selectedItemName];
      if ( selectedItem && selectedItem.background ) {
        background = selectedItem.background;
      }
    }

    return (
      <View style={{ backgroundColor: background, paddingTop: 10, paddingBottom: 0 }}>
        <FlatList
          style={{ paddingLeft: 5, textAlign: "center", alignSelf: "center"}}
          horizontal
          keyExtractor={item => item.name}
          data={this.props.menu.sequence.map(menuName => this.props.menu.index[menuName])}
          renderItem={({ item }) => (
            <View><MenuItem menu={item} onPress={()=>{ onMenuPress(item); }}/></View>
          )}
        />
        <View style={{ height: 1, backgroundColor: "#DBDBDB"}}/>
      </View>
    );
  }
};

export default MenuStrip;

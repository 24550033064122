import React from 'react';
import {
  View,
  Text,
  Image,
  Animated,
  Easing,
} from 'react-native';

export default class HamburgerOverlay extends React.Component {

  constructor(props) {
    super(props);
    this.state = { animation: new Animated.Value(props.active ? 1 : 0)};
  }

  // Set initla state
  componentDidMount() {
    //this.state = { rotation: Animated.value(this.props.rotation || 0) };
  }

  // Auto scroll to bottom after extending
  componentDidUpdate(prevProps, prevState) {
    if ( this.props.active !== prevProps.active ) {
      Animated.timing(
           this.state.animation,
           {
             toValue: this.props.active ? 1 : 0,
             duration: 300,
             easing: Easing.back()
           }
         ).start();
    }
  }

  render() {
    return (
      <Animated.View style={{ backgroundColor: "white",
                     width: "100%",
                     height: "100%",
                     position: "fixed",
                     top: 0,
                     left: 0,
                     zIndex: 99,
                     pointerEvents: this.props.active ? "auto" : "none",
                     opacity: this.state.animation }}>
        <View style={{ maxWidth: 500, alignSelf: "center" }}>
          {this.props.children}
        </View>
      </Animated.View>
    )
  }
}

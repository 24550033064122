// src/Home.js
import React from 'react';
import {
  View,
  Text,
  FlatList,
  Dimensions,
  Platform,
} from 'react-native';
import PersonItem from '../../components/items/PersonItem';
import PEOPLE from '../../constants/people';

class PeopleStrip extends React.Component {

  // Track the window size
  state = Dimensions.get("window");

  // Update our dimensions now
  updateDimensions() {
    this.setState(Dimensions.get('window'));
  }

  componentWillMount() {
    if ( Platform.OS == "web" ) {
      window.addEventListener("resize", () => { this.updateDimensions() });
    }
    else {
      Dimensions.addEventListener("change", () => { this.updateDimensions() });
    }
  }

  componentWillUnmount() {
    if ( Platform.OS == "web" ) {
      window.removeEventListener("resize", () => { this.updateDimensions() });
    }
    else {
      Dimensions.removeEventListener("change", () => { this.updateDimensions() });
    }
  }

  onPersonPress(person) {
    if (this.props.onPersonPress) {
      this.props.onPersonPress(person);
    }
  }

  render() {

    // How much padding to do
    let margins = 2;
    if ( this.state.width > 550 ) {
      margins = 15;
    }

    // Optional line
    let line = <View/>;
    if ( this.props.line ) {
      line = <View style={{ height: 1, marginTop: 10, backgroundColor: "#DBDBDB"}}/>;
    }

    return (
      <View style={{ backgroundColor: "#ffffff", paddingTop: 5, paddingBottom: 0 }}>
        <FlatList
          style={{ textAlign: "center", alignSelf: "center"}}
          horizontal
          keyExtractor={person => person.name}
          data={this.props.people.map(personName => PEOPLE.index[personName])}
          renderItem={({ item }) => (
            <View><PersonItem margins={margins} person={item} onPersonPress={()=>{ this.onPersonPress(item); }}/></View>
          )}
        />
        {line}
      </View>
    );
  }
};
export default PeopleStrip;

import { AppState } from "react-native";

export default {
    "patient-timeline": {
      era: "picnic",
      name: "patient-timeline",
      title: "Patient Timeline 2.0",
      subtitle: "Typescript, React, Node",
      categories: ["app", "web"],
      button: { text: "View Demo", url: "https://demo.picnichealth.com/medical-records" },
      searchOrder: 2,
      description: "AI-generated timeline of medical history, including visits, lab results, and imaging. Accessible on desktop/mobile, filterable, and shareable with doctors and family.",
      image: "picnic/timeline8",
      content: [
        {text: "AI-generated timeline of medical history, including visits, lab results, and imaging. Accessible on desktop/mobile, filterable, and shareable with doctors and family."},
        {text: "I led a team of 6 on this project, as we completed a full re-design of the patient timeline.  The re-design included a new UI leveraging our design system components, updated company brand, and patient feedback and analytics."},
        {text: "We also did a complete re-design under the hood, adopting a new GraphQL based API with progressive data loading.  As the medical timeline can contains hundreds of medical events for our most vulnerable patient populations, progressive rendering was important for a good user experience."},
        {photo: "content/picnic/timeline-xrays", aspect: 1.27, maxWidth: 700},
      ]
    },
    "ai-model-api": {
      era: "picnic",
      name: "ai-model-api",
      title: "Picnic AI",
      subtitle: "Python, Custom AI Model",
      categories: ["cloud"],
      searchOrder: 2,
      description: "PicnicAI interprets medical records with 3X more accuracy than GPT-4, identifying medications, conditions, visits, lab data, and outcomes.  Data is structured in a universal format.",
      image: "picnic/ai-api13",
      content: [
        {text: "PicnicAI interprets medical records with 3X more accuracy than GPT-4, identifying medications, conditions, visits, lab data, and outcomes.  Data is structured in a universal format."},
        {text: "During my second year at PicnicHealth, I directed the work of multiple teams, including our supervised learning app team, and ML team. During this time, we automated a new step in clinician workflows that decreased manual work by 35% and improved the accuracy of ML-based identification of lab results and medications by 20%."}, 
        {text: "The ML team was responsible for continued development on the AI model, and the API the rest of our software used to interact with it. The API provided access to predictions from the model, and training data from our supervised learning app to the model."},
        {text: "We regularly retrained our ML models based on new medical data, and auto-scaled our use of GPUs in the cloud based on training and medical records analysis needs."},
        {text: "We used several layers of ML models to interpret medical records, allowing us to start with thousands of pages of scanned records for a patient and end up with a key understanding of a disease's progression over time and reaction to medications."},
        {photo: "/content/picnic/system-overview3", aspect: 1.8, maxWidth: 700},
      ]
    },
    "hipe": {
      era: "picnic",
      name: "hipe",
      title: "Supervised Learning App", /*Medical Records ML*/
      subtitle: "Typescript, React, Node",
      categories: ["app"],
      searchOrder: 2,
      description: "Our staff of 500+ clinicians uses this app daily, validating AI analysis of patient records, refining our AI model and ensuring data accuracy for patients, doctors, and researchers.",
      /*image: "picnic/hipe9",*/
      image: "picnic/hipe6",
      content: [
        {text: "Our staff of 500+ clinicians uses this app daily, validating AI analysis of patient records, refining our AI model and ensuring data accuracy for patients, doctors, and researchers."},
        {text: "During my second year at PicnicHealth, I managed the team working on this app, and led a significant re-design aimed at producing higher efficiency for users and changing how we trained our ML models to structure and interpret medical data."},
        {text: "Measured improvements from this work included reducing 35% of manual work for our staff, and improving the accuracy of extracting lab results and medications by 20%."},
        {text: "We also improved our processes for testing and deploying changes to the app, including a system for progressive rollout of new features across our users."},
        {text: "To support a large ongoing scope of work, I grew the team working on the app from 3 to 7 developers, and mentored a future leader for this team."},
      ],  
    },
    "hasura-picnic": {
      era: "picnic",
      name: "hasura-picnic",
      title: "Medical GraphQL API",
      subtitle: "Hasura, Node, Apollo Client",
      categories: ["cloud"],
      searchOrder: 2,
      description: "Serves 30K+ patients and the 500+ full-time clinicians who train our AI, ensuring high availability, fast performance, and SOC2 & HIPAA compliance.",
      image: "picnic/graphql-hasura11",
      content: [
        {text: "Serves 30K+ patients and the 500+ full-time clinicians who train our AI, ensuring high availability, fast performance, and SOC2 & HIPAA compliance."},
        {text: "I led the team that created our GraphQL API, and rolled it out across multiple apps.  We chose Apollo Client for the front-end and Hasura for the back-end.  Hasura allowed us to quickly build a GraphQL API from our extensive Postgres database, with support for granular access control."},
        {text: "Our GraphQL API replaced a REST API that previously served a large and fixed amount of information. GraphQL's flexibility allowed us to serve only the data needed for each screen, and to add new features and data types without changing the API."},
        {photo: "/content/picnic/system-overview3", aspect: 1.8, maxWidth: 700},
      ]
    },
    "patient-surveys": {
      era: "picnic",
      name: "patient-surveys",
      title: "Patient Survey Platform",
      subtitle: "Typescript, React, Node",
      categories: ["app", "web"],
      searchOrder: 2,
      description: "Research studies have collected 20K patient surveys to date on the platform.  Surveys are designed in the FLEX no-code environment and can be completed on mobile or desktop.",
      image: "picnic/survey3",
      content: [
        {text: "Research studies have collected 20K patient surveys to date on the platform.  Surveys are designed in the FLEX no-code environment and can be completed on mobile or desktop."},
        {text: "I led this project from design to delivery in 6 months, with a team of 4 engineers and 1 designer.  "},
        {text: "The survey design and data was stored in Postgres, read/written via GraphQL APIs, and presented to users with Typescript and React."},
        {text: "Surveys are customized for each research study, and typically included questions about patient symptoms, medications, and quality of life, and were used to help researchers understand the impact of medical conditions and new treatments on patients."},
      ]
    },
    "flex-custom-studies": {
      era: "picnic",
      name: "flex-custom-studies",
      title: "FLEX No-code Study IDE",
      subtitle: "Typescript, React, Node",
      //subtitle: "No-code Study Setup",
      categories: ["app"],
      searchOrder: 2,
      description: "FLEX lets non-developers design research studies, including patient surveys, sign-up flows, screening steps, and AI extraction of insights tailored to new medical conditions.",
      image: "picnic/flex14",
      content: [
        {text: "FLEX lets non-developers design research studies, including patient surveys, screening steps, consent forms, and AI extraction of insights tailored to new medical conditions."}
      ]
    },
    "kubernetes-feature-branches-picnic": {
      era: "picnic",
      name: "kubernetes-feature-branches-picnic",
      title: "Feature Branch Publisher",
      subtitle: "Kubernetes, Docker",
      categories: ["cloud"],
      searchOrder: 2,
      description: "Command line tool lets developers publish a complete feature branch to a Kubernetes cluster, with a single command. This enabled rapid testing and feedback on new features.",
      image: "picnic/picnic-feature-branches6",
      content: [
        {text: "Command line tool let developers publish a complete feature branch in a Kubernetes cluster, with a single command. This enabled rapid testing and feedback on new features."},
        {text: "My role with this tool was championing its creation, designing the command line interface, and working with a senior engineer on my team to build it.  I also worked with other teams to encourage wide adoption."}
      ]
    },
    "mindb": {
      era: "picnic",
      name: "mindb",
      title: "MinDB",
      subtitle: "Database Minifier",
      categories: ["cloud"],
      searchOrder: 2,
      description: "MinDB creates minimal database copies for testing, excluding confidential patient data. Additional anonymized data can be requested for debugging specific scenarios.",
      image: "picnic/mindb10",
      content: [
        {text: "MinDB creates minimal database copies for testing, excluding confidential patient data. Additional anonymized data can be requested for debugging specific scenarios."},
        {text: "My role with MinDB was initiating the idea, specifying the command line interface and key requirements, getting buy-in from our software org, and working with a senior engineer to architect and implement it."},
        {text: "The implementation we ended up with automatically adapted to future changes in our data model, since it was built as a general tool that studied the current structure of the production database and could recursively follow foriegn key relationships."},
        {text: "If a bug occurred in production, developers could use the command line tool to request any database entry.  The tool would then recursively download all necessary data linked via foreign keys to this database row, with built in cycle protection, and anonymize any confidential columns."},
        {text: "Prior to MinDB, it was a struggle for developers to get the data they needed for testing and debugging.  MinDB helped unlock more adoption of integration tests, less reported bugs, and quicker turnaround time on fixes."},
      ]
    },
    "design-system-expansion": {
      era: "picnic",
      name: "design-system-expansion",
      title: "Basket Design System",
      subtitle: "Figma, Typescript",
      button: { text: "View in Storybook", url: "https://basket.design" },
      categories: ["web"],
      searchOrder: 2,
      description: "Basket Design is a unified design language and library of re-usable components.  It's used across several apps serving patients, researchers, and clinicians.",
      image: "picnic/basket-design5",
      content: [
        {text: "Basket Design is our unified design language and library of re-usable components.  It's used across several apps serving patients, researchers, and clinicians."},
        {text: "When I arrived at PicnicHealth, 1 front-end engineers and 1 designer had been working on the beginnings of a design system with 5 components, but it wasn't in use yet.  I guided our other designers, and other engineers to begin using the design system and contributing to it.  We expanded the design system to over 100 re-usable components, and rolled it out throughout our apps."},   
        {text: "As a team, we needed to support an upcoming rebrand of the company and its apps.  We leveraged the design system to support this change, building in support for both the old and new brand colors and styling into each component.  This allowed us to flip a switch and rebrand our externally facing apps at the same time as our company website."}
      ]
    },
    "postgres-infra-metabase-audit": {
      era: "picnic",
      name: "postgres-infra-metabase-audit",
      title: "SQL Query Auditor",
      subtitle: "Javascript",
      categories: ["cloud"],
      searchOrder: 2,
      description: "Metascan is a CLI tool I built to help developers understand if proposed data model changes could impact our organization's library of 400+ SQL queries in Metabase.",
      image: "picnic/metabase7",
      content: [
        {text: "Metascan is a CLI tool I built to help developers understand if proposed data model changes could impact our organizations' library of 400+ SQL queries in Metabase.  Metabase is a self-hosted 3rd party tool our organization used to host a shared library of queries, reports, and dashboards."},
        {text: "Prior to this tool, there were multiple times where a developer altered a column, and it broke downstream queries made by other teams outside of software.  Manually reviewing all 400 queries each time we made a change wasn't workable, and I wanted to provide the team with an alternative."},
        {text: "In my after hours time, I built an open source npm package that could walk any arbitrarily complex PSQL query and extract all column dependencies."},
        {link: "🧑🏻‍💻 View pg-query-walker on GitHub", url: "https://github.com/delaurentis/pg-query-walker" }, /* color: "#aa39fd", */
        {text:  "I then used this JS package to create a tool called Metascan that would connect to Metabase's underlying SQL database, download all 400+ queries, parse them, and extract a list of columns used.  The columns the developer was modifying would then be compared with this list, and they'd be informed of any queries impacted, and the authors of those queries."},
        {text: "I delivered the tool within a week of the core problem being identified. In the months after we began using this tool, instances of unexpected query failures caused by software releases dropped to zero."},
      ]
    },
    "hipe-patient-infrastructure-team": {
      era: "picnic",
      name: "hipe-patient-infrastructure-team",
      title: "HIPE / Patient / Infrastructure Team",
      categories: ["management"],
      searchOrder: 2,
      description: "Description for HIPE / Patient / Infrastructure Team.",
      image: "picnic/hipe-patient-infrastructure-team",
      content: [
        {text: "More details about HIPE / Patient / Infrastructure Team."}
      ]
    },
    "hiring-exercises-picnic": {
      era: "picnic",
      name: "hiring-exercises-picnic",
      title: "Hiring Exercises",
      categories: ["management"],
      searchOrder: 2,
      description: "Description for Hiring Exercises.",
      image: "picnic/hiring-exercises-picnic",
      content: [
        {text: "More details about Hiring Exercises."}
      ]
    }
  }
  
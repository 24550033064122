import PETE from "./people/pete"
import FERNANDO from './people/fernando'
import RONNY from './people/ronny'

export default {
  index: {
    "pete": PETE,
    "fernando": FERNANDO,
    "ronny": RONNY,
  },
  sequence: [ "pete", "fernando", "ronny" ]
};

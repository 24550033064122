import React from 'react';
import { View } from 'react-native';
import StickyNode from 'react-stickynode';

export default class Sticky extends React.Component {
  render() {
    return (
      <StickyNode enabled={true} top={this.props.top || 0} bottomBoundary={this.props.bottomBoundary || 0} innerZ={this.props.innerZ || 10}>
        {this.props.children}
      </StickyNode>);
  }
}

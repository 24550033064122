import React from 'react';
import {
  Text,
  View,
  unstable_createElement as createElement, /* deprecated after development */
} from 'react-native';
import HigherVideoBase from './HigherVideoBase';

export default class HigherVideo extends HigherVideoBase {

  // Reference from: https://github.com/QuantumBA/react-native-web-video/blob/master/src/Video.js
  render() {
    const props = this.props;
    const {
      source,
      poster,
      volume,
      controls,
      style,
    } = this.props;
    return createElement('video', {
      ref: "video",
      src: source.uri || source,
      poster: poster,
      muted: this.props.isMuted ? "true" : undefined,
      loop: this.props.isLooping ? "true" : undefined,
      autoplay: (this.props.autoplay || this.props.shouldPlay) ? "true" : undefined,
      controls: this.props.useNativeControls ? "true" : undefined,
      onEnded: this.props.onEnd,
      volume,
      style,
    });
  }

  // Play the video
  play() {
    if ( this.refs.video ) {
      this.refs.video.play();
    }
  }

  // Stop the video
  stop() {
    if ( this.refs.video ) {
      this.refs.video.pause();
      this.refs.video.currentTime = 0;
      // this.refs.video.load();
    }
  }
}

export default {
  "storyjar": {
    era: "personal",
    name: "storyjar",
    image: "storyjar/scene-zoom-out",
    theme: "light",
    aspectRatio: 0.66,
    searchOrder: 10,
    thumb: { resolution: 2048, zoom: 3.8, x: 1.47, y: 0.63 },
    title: "StoryJar",
    subtitle: "App",
    categories: ["app"],
    description: "A platform I designed for interactive digital storytelling.  Kids and adults alike could create compelling interactive stories.  The goal: an infinite library of kid powered content.",
//render: () => { return <TextBladeProject/> },
    content: [
      { description: true },
      { text: "When the iPad was first released in 2010, I began to design and build a platform for interactive storytelling.  There was an editor for creating interactive stories, and a high performance engine for playing them.  Artists could upload and sell 'art packs', which could be used by story creators." },
      { text: "To test the platform during development, I designed a first story and a set of flagship characters.  I was the sole developer on the product, and I eventually paused work on it to focus on my family and full-time job." },
      { photo: "projects/storyjar/scene-ice-cream-truck", aspect: 4800 / 3200 },
      { photo: "projects/storyjar/scene-beach ", aspect: 2947 / 1965 },
      { space: 20 },
      { text: "The editor let you position the character in different keyframes and relative to other objects in the scene.  Between keyframes, the character would automatically animate between positions (walking, jumping, etc.)" },
      { photo: "projects/storyjar/editor-iphone-character", aspect: 320 / 480, maxWidth: 320, marginLeft: 10 },
      { space: 20 },
      { text: "Theatre mode allowed you to both edit and preview the scene." },
      { photo: "projects/storyjar/editor-iphone-scene", aspect: 960 / 640, maxWidth: 960, marginLeft: 10 },
      { space: 20 },
      { text: "The pre-launch website..." },
      { photo: "projects/storyjar/storyjar-website-2048", aspect: 19282 / 21656, maxWidth: 960, marginLeft: 10 },
    ],
  },
};

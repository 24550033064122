export default {
  name: "ronny",
  image: "ronny",
  url: "/ronny",
  firstName: "Ronny",
  lastName: "Islam",
  title: "Product Designer",
  categories: ["hardware", "video"],
  projects: [
             ["textblade-keyboard", "textblade-app", "textblade-international-layouts",],
             ["textblade-order-system", ],
           ],
  shortDescription: "Lorem ipsum dolor fugis epitat.",
  description: "Lorem ipsum dolor fugis epitat\nipsum dolor epitat vidi vini vicci.",
  menu: {
    index: {
      top: {
        name: "top",
        title: "RONNY"
      },
      portfolio: {
        name: "portfolio",
        title: "PORTFOLIO"
      },
      cv: {
        name: "cv",
        title: "CV",
      },
      skills: {
        name: "skills",
        title: "SKILLS",
      },
      about: {
        name: "about",
        title: "ABOUT",
      },
      talk: {
        name: "talk",
        title: "TALK",
      },
    },
    sequence: ["top", "portfolio", "cv", "skills", "about", "talk"]
  },
  footer: {
    columnWidth: 250,
    index: {
      copyright: {
          symbol: "copyright",
          text: "2019 Ronny Islam",
      },
      location: {
          symbol: "location",
          text: "Los Angeles, CA",
      },
      location: {
          symbol: "linkedin",
          url: "https://www.linkedin.com/in/ronnyislam/",
          text: "Connect on LinkedIn",
      },
      email: {
          symbol: "email",
          url: "mailto:fernandomora@gmail.com",
          text: "fernandomora@gmail.com",
      },

    },
    sequence: ["location", "email"]
  },
  about: {
    paragraphs: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam in sollicitudin leo, a euismod lectus. Nunc vitae tincidunt felis. Integer in lorem molestie, mattis mauris eu, tincidunt felis. Praesent auctor ullamcorper viverra.",
      "Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer nec urna interdum, interdum leo ac, aliquet nulla. Nulla et auctor neque, eget vulputate mauris. Etiam ac est finibus, tristique diam vitae.",
      "Aliquam rutrum mauris sem, sed blandit justo aliquam quis. Aliquam eget erat eget libero ultrices pulvinar. Donec placerat, ex ac suscipit viverra, erat enim feugiat elit, non aliquet nibh nunc id justo.",
    ],
  },
  talk: {
    email: "fernandomora@gmail.com",
    button: "Yes, Let's Connect!",
    paragraphs: [
      "Want to explore working together?"
    ],
  },
  jobs: {
    description: "Veteran cloud architect. 10x engineer.",
    index: {
      upgift: {
        name: "upgift",
        where: "UpGift",
        when: "2017 - PRESENT",
        project: "UpGift Experience Platform",
        role: "Lead Software Engineer",
        narrowRole: "Lead Software Engineer",
        description: "Consumer app transforms how we give to unlock the experience economy.",
        bullets: [
          [
            "One",
            "Two",
            "Three",
          ],
          [
            "Four",
            "Five",
            "Six",
          ],
        ],
      },
      waytools: {
        name: "waytools",
        where: "WayTools",
        when: "2011 - 2017",
        project: "TextBlade Multi-Touch Keyboard",
        role: "Senior Software Engineer",
        narrowRole: "Senior Software Engineer",
        description: "Full QWERTY keyboard, packs to 1/3rd size of iPhone.  Magnetic suspension +  luxurious key feel.",
        bullets: [
          [
            "Developed customer facing TextBlade iOS app",
            "Developed company website, order system, factory software, cloud systems",
            "Developed advanced diagnostic software, including remote oscilliscope + flight recorder",
            "Designed and built system for communicating with customers and collecting detailed logs",
          ],
          [
            "Designed LiveDoc cloud database with real-time syncing, redundancy, ability to scale at low cost",
            "Designed fault-tolerant, encrypted protocol between firmware + iOS + cloud for reliable OTA updates",
            "Managed weekly roll-out of OTA firmware updates to customers in the field",
            "Designed FingerBot, an automated QA robot + an iOS app to operate a fleet of QA robots.",
          ],
        ],
      },
      nextengine: {
        name: "nextengine",
        where: "NextEngine",
        when: "2007 - 2011",
        project: "NextEngine Desktop 3D Scanner",
        role: "Software Engineer",
        narrowRole: "Software Engineer",
        description: "Best-selling 3D scanner in world. Used in 90 + countries for science, manufacturing, education, film.",
        bullets: [
          [
            "Built real-time customer support into app, so customers + staff experts could collaborate on 3D scans",
            "Architected cloud systems for log capture, user / device analytics, and OTA updates",
            "Designed secure automated Software licensing system using public key cryptography",
          ],
          [
            "Four",
            "Five",
            "Six",
          ],
        ],
      },
      ucsb: {
        name: "ucsb",
        type: "education",
        where: "Education",
        when: "???? - ????",
        project: "UC Santa Barbara",
        role: "B.S. Computer Science",
        narrowRole: "BS Computer Science",
        bullets: [
          [
            "One",
            "Two",
            "Three",
          ],
        ],
      },
    },
    sequence: ["upgift", "waytools", "nextengine", "ucsb"]
  },
  skills: {
    description: "Lorem ipsum dolor fugis epitat.",
    index: {
      design: {
        name: "design",
        title: "Design",
        bullets: [
          "Serverless Architecture",
          "Cloud Architecture",
          "App Architecture",
          "API Design",
          "Protocol Design",
          "Database Design",
          "DB Query Optimization",
          "Secure Payment Systems",
          "PCI Compliant Systems",
          "Realtime Collaboration Systems",
        ],
      },
      tools: {
        name: "tools",
        title: "Tools",
        bullets: [
          "Serverless Framework",
          "Amazon AWS",
          "Amazon DynamoDB",
          "Amazon Lambda",
          "Amazon Cloudfront",
          "Xcode",
          "Android Studio",
          "Adobe Photoshop",
          "Atom",
          "Git",
        ],
      },
      code: {
        name: "code",
        title: "Code",
        bullets: [
          "React",
          "React Native",
          "Objective-C",
          "HTML / CSS",
          "JavaScript",
          "Node.js",
          "Ruby",
          "Java",
          "C++",
          "C",
        ],
      },
      lead: {
        name: "lead",
        title: "Leadership",
        bullets: [
          "Humility",
          "Empathy",
          "Love of Learning",
          "Boundless Curiosity",
          "Consensus Building",
          "Active Listening",
          "Product Management",
          "Plans for Extensibility",
          "Mentors Teammates",
          "Calm under Fire",
        ],
      },
    },
    sequence: ["design", "tools", "code", "lead"],
  },
};

import React from 'react';
import { StyleSheet, Text, View, FlatList } from 'react-native';
import JobBulletItem from '../../components/items/JobBulletItem';

class JobBulletSet extends React.Component {

  render() {

    // Return it now
    return (
      <View style={{ marginTop: this.props.narrow ? 0 : 10 }}>
        {this.props.bullets.map((bullet) => {
          return <JobBulletItem narrow={this.props.narrow} bullet={bullet}/>;
        })}
      </View>
    );
  }
};

export default JobBulletSet;

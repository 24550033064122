// These actions are for modifying lists
export const CHOOSE_CATEGORY = "CHOOSE_CATEGORY";
export const CHOOSE_PROJECT = "CHOOSE_PROJECT";
export const CHOOSE_CARD = "CHOOSE_CARD";
export const CLOSE_CARD = "CLOSE_CARD";
export const CHOOSE_MENU = "CHOOSE_MENU";
export const ENABLE_MENU = "ENABLE_MENU";
export const DISABLE_MENU = "DISABLE_MENU";
export const OPEN_HAMBURGER = "OPEN_HAMBURGER";
export const CLOSE_HAMBURGER = "CLOSE_HAMBURGER";

export default {
  "textblade-keyboard": {
    era: "waytools",
    name: "textblade-keyboard",
    image: "waytools/textblade-keyboard",
    theme: "light",
    aspectRatio: 0.40,
    /*aspectRatio: 0.40,*/
    title: "TextBlade Keyboard",
    subtitle: "Hardware",
    categories: ["hardware"],
    searchOrder: 1,
    button: { text: "Demo", video: "textblade-720" },
    description: "Full QWERTY keyboard magnetically self-packs to 1/3rd size of iPhone. 101 keys are replaced by one smart key per finger.   Keys are multi-touch surfaces with luxurious key travel.",
    /*Full QWERTY keyboard, packs to 1/3rd size of iPhone.  Magnetic suspension +  luxurious key feel.*/
    content: [
      { text: "TextBlade is a full QWERTY keyboard that magnetically self-packs to 1/3rd size of iPhone. 101 keys are replaced by one smart key per finger. Each key features a MultiTouch surface which detects the position of your finger, and a magnetic suspension for luxurious key feel.", },
      { if: "pete", space: 5 },
      { if: "pete", photo: "content/textblade/low-profile.jpg", aspect: 1500 / 191, maxWidth: 700, marginRight: 10 },
      { if: "pete", space: 5 },
      { text: "Customers have found that they can type faster on TextBlade than a traditional keyboard, and universally prefer the improved key feel, ergonomics, and user experience." },
      { if: "pete", space: 10 },
      { if: "pete", photo: "content/textblade/textblade-stack.jpg", aspect: 2441 / 696, maxWidth: 700, marginRight: 10 },
      { if: "pete", space: 10 },
      { if: "pete", text: "I designed the TextBlade user experience, including patented Home Row Cursor, MultiLayer Keys. I also designed 12 variations of TextBlade layout to support international customers. I worked worked with mechanical, electrical, firmware, app, server engineers to refine + implement designs." },
      { if: "pete", text: "I was a member of a 5 person team responsible for TextBlade industrial design, color, layout, ergonomics. I also designed the WayTools and TextBlade logos and 2D symbol stencil artwork." },
      { if: "pete", text: "After designing the TextBlade, I led design and development of all factory software responsible for producing, testing, and shipping TextBlades." },
      { space: 10 },
      { if: "pete", photo: "content/textblade/so-many.jpg", aspect: 2048 / 1200 },
      /*{ video: "textblade-720" },*/
    ],
  },
  "textblade-app": {
    era: "waytools",
    name: "textblade-app",
    image: "waytools/textblade-app",
    theme: "dark",
    aspectRatio: 0.40,
    searchOrder: 4,
    title: "TextBlade for iOS",
    subtitle: "App, Keyboard Extension",
    categories: ["app"],
    button: { text: "Demo", video: "textblade/multimap" },
    description: "Companion app makes it easy to upgrade TextBlade with custom maps, macros, and the latest firmware.  Keyboard extension provides a cheat sheet of all features as you type.",
    content: [
      { description: true, },
      { if: "pete", text: "I designed the TextBlade app and led the team that developed it. We built the iOS app with Objective-C, and also ported limited functionality to Android using Java." },
      { if: "pete", video: "textblade/jumps-firmware-update" },
    ],
  },
  "realtime-device-sync": {
    era: "waytools",
    name: "realtime-device-sync",
    image: "waytools/realtime-device-sync-wide",
    theme: "dark",
    aspectRatio: 0.37,
    title: "Realtime Device Sync",
    subtitle: "Cloud Service",
    categories: ["cloud"],
    searchOrder: 4,
    description: "TextBlade users switch effortlessly from typing on one screen to another. Settings, layouts, and macros must sync in real-time across all devices.  Used custom Firebase-like engine.",
    content: [
      { description: true },
      { if: "pete", text: "I designed a system called LiveDoc, which wrapped MongoDB and provided Objective-C and JavaScript APIs for direct, secure, and real-time synced access to data.  Schemas could be defined in the fly on the client, allowing our team to add new functionality with zero server development cost." },
      { if: "pete", text: "LiveDoc was developed prior to the launch of Firebase, and was very similiar – except that it could be deployed anywhere.  We deployed with Amazon Web Services to maximize reliability." },
    ],
  },
  "textblade-international-layouts": {
    era: "waytools",
    name: "textblade-international-layouts",
    image: "waytools/textblade-international-layouts",
    theme: "light",
    aspectRatio: 0.38,
    title: "7 International Layouts",
    subtitle: "Hardware",
    searchOrder: 10,
    categories: ["hardware"],
    description: "Created layouts and artwork for French, Spanish, Italian, German, Swedish, Danish,  Korean, and alt-standards Colemak and Dvorak.",
    content: [
      { text: "Created layouts and artwork for French, Spanish, Italian, German, Swedish, Danish,  Korean, and alt-standards Colemak and Dvorak.", },
      { if: "pete", text: "I did a comprehensive survey of existing keyboard layouts, studying the importance and cultural context of different symbols used.  I then attempted to design a layout for each language that would at the same time be familiar, and also optimize access to commonly used symbols.  My goal was for each layout to be faster than existing keyboards for writing in the target language." },
      { if: "pete", text: "I edited many of the symbols by hand in Adobe Illustrator, to optimize them for readability at the scale and ink thickness used to print symbols on the TextBlade.  I created  files for submission to our printing vendors in Malaysia." },
    ],
  },
  "textblade-website": {
    era: "waytools",
    name: "textblade-website",
    image: "waytools/textblade-site-flip-bright",
    aspectRatio: 0.62,
    theme: "light",
    title: "TextBlade Website",
    subtitle: "Web",
    categories: ["web"],
    searchOrder: 4,
    /*button: { text: "Demo", url: "https://waytools.com" },*/
    description: "Features a gallery of rich video content celebrating the TextBlade and educating users on how it works.  The gallery combines powerful navigation with a seamless and fluid experience.",
    content: [
      { description: true },
      { if: "pete", text: "I designed the TextBlade website and led the team that developed it. We built the website using HTML5, CSS, and JavaScript." },
    ],
  },
  "textblade-video": {
    era: "waytools",
    name: "textblade-video",
    image: "waytools/textblade-on-table",
    theme: "light",
    title: "Introducing TextBlade",
    subtitle: "Video",
    categories: ["video"],
    button: { text: "Demo", video: "textblade-720" },
    description: "Video introduces TextBlade to the world, racking up 600K organic views,  and resulting in $ millions in product sales.  All filming, rendering, and editing was done in-house.",
    descriptionForPete: "Video introduces TextBlade to the world, racking up 600K organic views,  and resulting in $ millions in product sales.  I scripted, starred in, filmed, and edited the video.",
    content: [
      { text: "Video introduces TextBlade to the world, racking up 600K organic views,  and resulting in $ millions in product sales.  All filming, rendering, and editing was done in house.", },
      { if: "pete", text: "I scripted, filmed, and edited the video.  I also did all the typing shown in the video, and built a custom light table and studio setup for filming." },
    ],
  },
  "smarter-keys-video": {
    era: "waytools",
    name: "smarter-keys-video",
    image: "waytools/smarter-keys",
    theme: "light",
    title: "Smarter Keys",
    subtitle: "Video",
    categories: ["video"],
    description: "Aliquam erat volutpat. Fusce non est sit amet turpis porttitor tristique. Aenean posuere metus convallis neque fringilla, eget fermentum arcu gravida.",
    content: [
      { text: "Aliquam erat volutpat. Fusce non est sit amet turpis porttitor tristique. Aenean posuere metus convallis neque fringilla, eget fermentum arcu gravida.", },
    ],
  },
  "universal-keyboard-video": {
    era: "waytools",
    name: "universal-keyboard-video",
    image: "waytools/universal",
    theme: "light",
    title: "Universal Keyboard",
    subtitle: "Video",
    categories: ["video"],
    button: { text: "Demo", video: "textblade/universal" },
    description: "\TextBlade is designed to work with all devices.  This video showcases popular iOS and Android devices in 2013, and tells the story of the Universal Keyboard.",
    content: [
      { description: true },
      { if: "pete", text: "We created the video with Adobe After Effects, Illustrator, and Final Cut.  I assisted with live action filming and background audio." },
    ],
  },
  "hands-on-video": {
    era: "waytools",
    name: "hands-on-video",
    image: "waytools/hands-on-video",
    theme: "light",
    title: "Hands-On Event",
    subtitle: "Video",
    categories: ["video"],
    button: { text: "Demo", resolution: "original", video: "textblade/hands-on" },
    description: "The public gets hands on TextBlade for the first time at our Santa Monica office.  Video footage was captured from multiple cameras, and edited together to tell a powerful story.",
    content: [
      { description: true },
      { if: "pete", text: "We worked with a team of videographers to capture the raw footage, and I did all editing using Final Cut.  My goal was to condense hours of footage from multiple cameras into a cohesive, emotionally impactful story." },
    ],
  },
  "what-matters-video": {
    era: "waytools",
    name: "what-matters-video",
    image: "image",
    theme: "light",
    title: "What Matters",
    subtitle: "Video",
    categories: ["video"],
    button: { text: "Demo", video: "textblade/what-matters" },
    description: "Aliquam erat volutpat. Fusce non est sit amet turpis porttitor tristique. Aenean posuere metus convallis neque fringilla, eget fermentum arcu gravida.",
    content: [
      { text: "Aliquam erat volutpat. Fusce non est sit amet turpis porttitor tristique. Aenean posuere metus convallis neque fringilla, eget fermentum arcu gravida.", },
    ],
  },
  "multimap-video": {
    era: "waytools",
    name: "multimap-video",
    image: "waytools/textblade-blue-background",
    theme: "light",
    title: "Customizing TextBlade",
    subtitle: "Video",
    categories: ["video"],
    button: { text: "Demo", video: "textblade/multimap" },
    description: "Introduces the TextBlade app for iOS and shows users how to customize their TextBlades.",
    content: [
      { description: true },
      { if: "pete", text: "I scripted, filmed, and edited the video. The video was made on a custom light table that I built in my studio." },
    ],
  },
  "android-video": {
    era: "waytools",
    name: "android-video",
    image: "waytools/android-app",
    theme: "dark",
    title: "TextBlade for Android",
    subtitle: "Video",
    categories: ["video"],
    button: { text: "Demo", video: "textblade/android" },
    description: "This video shows a preview of an upcoming TextBlade app for Android, and typing on various Android devices.",
    content: [
      { description: true },
      { if: "pete", text: "I scripted, filmed, and edited the video. The video was made on a custom light table that I built in my studio." },
    ],
  },
  "textblade-patent": {
    era: "waytools",
    name: "textblade-patent",
    image: "waytools/multitouch-keys-patent",
    theme: "light",
    title: "Multi-Touch Keyboard",
    subtitle: "US Patent 9235270",
    categories: ["patent"],
    extraSearchCategories: ["hardware"],
    button: { text: " PDF ", url: "/patents/US9235270.pdf" },
    description: "TextBlade is a new hybrid keyboard, the first to combine multi-touch surfaces with mechanical key switches.  We were awarded a patent for this powerful combination.",
    content: [
      { description: true, },
      { if: "pete", text: "As a co-inventor of the hybrid keyboard, I helped the WayTools legal team craft the patent claims and embodiment." },
    ],
  },
  "separable-keyboard-patent": {
    era: "waytools",
    name: "separable-keyboard-patent",
    image: "waytools/separable-keyboard-patent",
    theme: "light",
    title: "Separable Mobile Keyboard",
    subtitle: "US Patent 8686948",
    categories: ["patent"],
    extraSearchCategories: ["hardware"],
    button: { text: " PDF ", url: "/patents/US8686948.pdf" },
    description: "Three parts attach to form a full keyboard with desktop-size key spacing, or magnetically self pack into a package 1/3rd the size of an iPhone.",
    content: [
      { description: true, },
    ],
  },
  "keyswitch-patent": {
    era: "waytools",
    name: "keyswitch-patent",
    image: "waytools/keyswitch-patent",
    theme: "light",
    title: "Magnetic Key Switch",
    subtitle: "US Patent 9728353",
    categories: ["patent"],
    extraSearchCategories: ["hardware"],
    button: { text: " PDF ", url: "/patents/US9728353.pdf" },
    description: "Keys are suspended by a magnetic field, and linked to interleaved levers providing high precision parallel travel. The mechanism creates smooth motion with a crisp, luxurious feel.",
    content: [
      { description: true, },
      { text: "Our team's patented design has been copied by Dell for the maglev keyboard in the latest high-end XPS laptops."}
    ],
  },
  "textblade-design-patent": {
    era: "waytools",
    name: "textblade-design-patent",
    image: "waytools/textblade-design-patent",
    theme: "light",
    title: "TextBlade Design",
    subtitle: "US Patent D757008",
    categories: ["patent"],
    extraSearchCategories: ["hardware"],
    button: { text: " PDF ", url: "/patents/D757008.pdf" },
    description: "Features the signature visual and ergonomic design elements of the TextBlade.",
    content: [
      { description: true },
      { if: "pete", text: "I helped to design the signature look and feel of the TextBlade, and am listed as a co-inventor on this patent." },
    ],
  },
  "textblade-cursor-patent": {
    era: "waytools",
    name: "textblade-cursor-patent",
    image: "waytools/embedded-cursor-patent",
    theme: "light",
    title: "Home Row Cursor",
    subtitle: "Patent Pending", /* 20160117097*/
    categories: ["patent"],
    extraSearchCategories: ["hardware"],
    button: { text: " PDF ", url: "/patents/US20160117097A1.pdf" },
    description: "An elegant and simple method that gives users full access to cursor and edit functions, without leaving the home row.  This feature is universally loved by TextBlade users.",
    content: [
      { description: true },
      { if: "pete", text: "I came up with this idea, and also helped the WayTools legal team to write the patent claims and embodiment." },
      { text: "An implementation of Home Row Cursor is also covered that can be applied to existing laptop keyboards." },
    ],
  },
  "textblade-modkeys-patent": {
    era: "waytools",
    name: "textblade-modkeys-patent",
    image: "waytools/modkeys-patent-padding",
    theme: "light",
    title: "Embedded Modifier Keys",
    subtitle: "Patent Pending", /* 20160117097*/
    categories: ["patent"],
    extraSearchCategories: ["hardware"],
    button: { text: " PDF ", url: "/patents/US20150277578A1.pdf" },
    description: "A method for faster, more ergonomic access to modified keys including Ctrl, Alt, and Command.  The system leverages simple chords that mirror existing modifier key locations.",
    content: [
      { description: true },
      { if: "pete", text: "I came up with this idea, and also helped the WayTools legal team to write the patent claims and embodiment." },
    ],
  },
  "textblade-order-system": {
    era: "waytools",
    name: "textblade-order-system",
    image: "waytools/textblade-order-system",
    theme: "light",
    title: "TextBlade Store",
    subtitle: "Web, Cloud",
    categories: ["web", "cloud"],
    searchOrder: 4,
    description: "Store showcases TextBlade and accessories. Distinct, beautiful experiences on desktop and mobile. Powered by a custom PCI-compliant order system designed in house.",
    content: [
      { text: "Store showcases TextBlade and accessories. Distinct, beautiful experiences on desktop and mobile. Powered by a custom PCI-compliant order system designed in house.", },
      { if: "pete", text: "I designed the store and built it using HTML5, CSS, and JavaScript.  I created 3D models and renderings for the store using Modo and SolidWorks." },
      { if: "pete", text: "I also designed the backend order system, and led our team responsible for development, load testing, and deployment.  The order system was powered by Amazon Web Services, Node.js, and MongoDB. I also created an internal iPhone app for managing customer orders and monitoring sales activity.", },
    ],
  },
  "remote-scopes": {
    era: "waytools",
    name: "remote-scopes",
    image: "waytools/remote-scopes",
    theme: "dark",
    aspectRatio: 0.40,
    title: "Remote Scopes",
    subtitle: "App, Cloud",
    categories: ["app", "cloud"],
    searchOrder: 4,
    button: { text: "Demo", video: "textblade/what-matters" },
    description: "TextBlade users get world-class support. All voltages in TextBlade can be streamed in real-time to WayTools engineers. This data led to key electrical and firmware upgrades.",
    content: [
      { description: true },
      { if: "pete", text: "I designed user interfaces for viewing and exploring different kinds of sensor data and helping us correlate sensor reading with text typed. I implemented the user interfaces in Objective-C, and integrated them into the TextBlade iOS app." },
      { if: "pete", text: "I filmed a Demo video for our customers that showcased these tools.  Press the Demo button to see them in action." },
      { if: "pete", text: "To implement these tools, I wrote software to communicate with the TextBlade firmware over Bluetooth Low Energy protocol and gather voltage and other sensory data.  I worked with my team to create an efficient cloud storage system for sensor data." },
    ],
  },
  "fingerbot": {
    era: "waytools",
    name: "fingerbot",
    /*image: "waytools/textblade-fingerbot",*/
    image: "waytools/fingerbot-top",
    theme: "dark",
    aspectRatio: 0.55,
    title: "FingerBot",
    subtitle: "Factory Hardware",
    searchOrder: 10,
    categories: ["hardware"],
    button: { text: "Demo", video: "textblade/fingerbot" },
    description: "Scriptable solenoid-powered robot can simulate any combination of finger positions.  Used for firmware quality assurance, edge case testing, and playback of user bug reports.",
    content: [
      { description: true },
      { if: "pete", text: "I collaborated with a teammate to develop the concept, source components, and design the software protocols and user interface for controlling the FingerBot.  Creating this tiny magnetic piston powered robot was a fun side-project we started, and it led to the development of a fleet of QA robots." },
    ],
  },
  "forcebot": {
    era: "waytools",
    name: "forcebot",
    image: "waytools/forcebot",
    theme: "light",
    aspectRatio: 0.55,
    searchOrder: 10,
    title: "ForceBot",
    button: { text: "Demo", video: "textblade/forcebot" },
    subtitle: "Factory Hardware",
    categories: ["hardware"],
    description: "A fleet of app-controlled ForceBots precisely measures the force curve of every TextBlade key, ensuring a satisfying luxury feel.  Measurements are stored in the cloud.",
    content: [
      { description: true },
      { if: "pete", text: "I led the team that developed the ForceBot software.  I designed the user interface for controlling the ForceBot and viewing its data.  I also helped to design the software protocols for communicating with the ForceBot over Bluetooth Low Energy and for storing its data in the cloud." },
    ],
  },
  "textblade-factory-app": {
    era: "waytools",
    name: "textblade-factory-app",
    image: "waytools/textblade-factory-app",
    theme: "dark",
    aspectRatio: 0.55,
    title: "BladeFactory",
    subtitle: "App",
    categories: ["app"],
    searchOrder: 4,
    description: "Aliquam erat volutpat. Fusce non est sit amet turpis porttitor tristique. Aenean posuere metus convallis neque fringilla, eget fermentum arcu gravida.",
    content: [
      { text: "Aliquam erat volutpat. Fusce non est sit amet turpis porttitor tristique. Aenean posuere metus convallis neque fringilla, eget fermentum arcu gravida.", },
    ],
  },
  "bladelog-app": {
    era: "waytools",
    name: "bladelog-app",
    image: "waytools/bladelog-app",
    theme: "dark",
    aspectRatio: 0.55,
    title: "BladeLog",
    subtitle: "App",
    categories: ["app"],
    searchOrder: 4,
    description: "Aliquam erat volutpat. Fusce non est sit amet turpis porttitor tristique. Aenean posuere metus convallis neque fringilla, eget fermentum arcu gravida.",
    content: [
      { text: "Aliquam erat volutpat. Fusce non est sit amet turpis porttitor tristique. Aenean posuere metus convallis neque fringilla, eget fermentum arcu gravida.", },
    ],
  },
  "ship-center": {
    era: "waytools",
    name: "ship-center",
    image: "waytools/ship-center",
    theme: "dark",
    aspectRatio: 0.55,
    title: "ShipCenter",
    subtitle: "App",
    categories: ["app"],
    description: "Aliquam erat volutpat. Fusce non est sit amet turpis porttitor tristique. Aenean posuere metus convallis neque fringilla, eget fermentum arcu gravida.",
    content: [
      { text: "Aliquam erat volutpat. Fusce non est sit amet turpis porttitor tristique. Aenean posuere metus convallis neque fringilla, eget fermentum arcu gravida.", },
    ],
  },

};

//https://res.cloudinary.com/higher-design/image/upload/v1552703321/people/pete.jpg
import React from 'react';
import {
  View,
  Image,
  Text,
  TouchableOpacity,
} from 'react-native';

export default class SimpleLinkSection extends React.Component {

  onPress() {
    if ( this.props.onPress ) {
      this.props.onPress();
    }
  }

  render() {

    // Is there a line?
    let line = <View/>;
    if ( this.props.line ) {
      line = <View style={{ height: 1,backgroundColor: "#DBDBDB"}}/>;
    }

    // Show our section
    return (
      <TouchableOpacity style={{ paddingTop: 0, textAlign: "center" }} onPress={() => { this.onPress(); }}>
          <Text style={{ marginTop: 15, marginBottom: 15, fontSize: 28, fontWeight: "800", fontFamily: 'System' }}>{this.props.title.toUpperCase()}</Text>
          {line}
      </TouchableOpacity>
    )
  }
}

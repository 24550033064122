import React from 'react';
import { Animated, Easing, View, Text, TouchableOpacity } from 'react-native';
/*import { Haptic } from 'expo';*/

export default class TextHeader extends React.Component {

  // Used for animations on press
  state = {
    opacity: new Animated.Value(0),
  }

  componentDidMount() {
    if ( this.props.line ) {
      this.state.opacity.setValue(1);
    }
    else {
      this.state.opacity.setValue(0);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if ( this.props.line !== prevProps.line ) {

      // Are we showing the line or not
      if ( this.props.line ) {
        Animated.timing(                     // Animate over time
             this.state.opacity,             // The animated value to drive
             {
               toValue: 1,
               duration: 500,
               useNativeDriver: true,
             }
           ).start();
      }
      else {
        this.state.opacity.setValue(0);
      }
    }
  }

  render() {

    // Is it touchable
    if ( this.props.touchable ) {
      return <View style={{ backgroundColor: this.props.background || "#f4f4f4" }}>
                <TouchableOpacity onPress={() => { if ( this.props.onPress ) { this.props.onPress(); } }}>
                  <Text style={{ cursor: 'pointer', paddingTop: this.props.paddingTop || 15, paddingBottom: 15, backgroundColor: this.props.background || "#f4f4f4", textAlign: "center", fontSize: 28, fontWeight: "800", fontFamily: 'System'}}>{this.props.title.toUpperCase()}</Text>
                </TouchableOpacity>
                <Animated.View style={{ height: 1, width: "100%", backgroundColor: "#DBDBDB", opacity: this.state.opacity }}/>
             </View>;
    }
    else {
      return <View style={{ backgroundColor: this.props.background || "#f4f4f4" }}>
                <Text style={{ paddingTop: this.props.paddingTop || 15, paddingBottom: 15, backgroundColor: this.props.background || "#f4f4f4", textAlign: "center", fontSize: 28, fontWeight: "800", fontFamily: 'System'}}>{this.props.title.toUpperCase()}</Text>
                <Animated.View style={{ height: 1, width: "100%", backgroundColor: "#DBDBDB", opacity: this.state.opacity }}/>
             </View>;

    }
  }
}

//https://res.cloudinary.com/higher-design/image/upload/v1552703321/people/pete.jpg
import React from 'react';
import {
  View,
  Image,
  Text,
  TouchableOpacity,
  Platform,
} from 'react-native';
import HigherGradient from '../../../components/utility/HigherGradient'
import PeopleStrip from '../../../components/strips/PeopleStrip';
import PEOPLE from '../../../constants/people';

export default class PortfolioLinkSection extends React.Component {

  // Jump to the given person
  onPersonPress(person) {
    if ( Platform.OS == "web" ) {
      window.open(person.url, "_blank");
    }
    else {
      if ( this.props.history ) {
        this.props.history.push(person.url);
      }
    }
  }

  onTitlePress() {
    if ( this.props.onPress ) {
      this.props.onPress();
    }
  }


  render() {

    // Show our section
    return (
      <HigherGradient
         direction="to bottom"
         from="#ffffff"
         to="#ffffff">
        <View style={{  textAlign: "center" }}>
            <TouchableOpacity onPress={() => { this.onTitlePress(); }}>
              <Text style={{ paddingTop: 15, paddingBottom: 10, fontSize: 28, fontWeight: "800", fontFamily: 'System' }}>PEOPLE</Text>
              <Text style={{ marginBottom: 10, fontSize: 16, fontWeight: "400", fontFamily: 'System' }}>Passion meets precision.</Text>
            </TouchableOpacity>
            <View style={{}}>
              <PeopleStrip line people={PEOPLE.sequence} onPersonPress={(person) => { this.onPersonPress(person); }}/>
            </View>
        </View>
      </HigherGradient>
    )

  }
}

import { View, Text } from 'react-native';

export default {
  "petedelaurentis.com": {
    routes: [
      {
        path:"/",
        /*exact: true,*/
        render: {
          type: "PersonPage",
          props: { personName: "pete" }
        }
      }
    ]
  },
  "fermora.com": {
    routes: [
      {
        path:"/",
        exact: true,
        render: {
          type: "PersonPage",
          props: { personName: "fernando" }
        }
      }
    ]
  },
  "main": {
    main: true
    // Nothing for now - See App.js for default main rendering
  }
};

import { CHOOSE_CARD, CLOSE_CARD } from './actionTypes.js'

export function chooseCard(cardName, cardType, x, y, width, height) {
  return { type: CHOOSE_CARD,
           card: { name: cardName, type: cardType, x: x, y: y, width: width, height: height } };
}

export function closeCard(project) {
  return { type: CLOSE_CARD }
}

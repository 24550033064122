import React from 'react';
import {
  SectionList,
  View
} from 'react-native';
import HigherSectionListBase from './HigherSectionListBase';
//import TwinSectionList from './TwinSectionList';

export default class HigherSectionList extends HigherSectionListBase {

  constructor (props) {
    super(props)

    // Ref: https://facebook.github.io/react-native/docs/flatlist#minimumviewtime
    // this._viewabilityConfig = {
    //     //waitForInteraction: true,
    //     viewAreaCoveragePercentThreshold: 95
    // }
  }

  remapSections() {
    //console.log("REMAP");
    //this._remapSections();
    //this._triggerLayout();
    //this._sectionList.recordInteraction();
  }

  render() {
    // Desctruct properties
    let {
      categoryName,
      renderItem,
      renderSectionHeader,
      itemVisiblePercentThreshold,
      extraData,
      ...props
    } = this.props;

    itemVisiblePercentThreshold = itemVisiblePercentThreshold || 10;

    // Rewrite render item
    let _renderItem = ({item, index, section})=>{

      // Build parent key
      let key = `parent${index}`;

      // Update header style if sticky section headers are enabled
      let headerStyle = {};
      if (props.stickySectionHeadersEnabled) {
        headerStyle.zIndex = 2;
        headerStyle.position = "sticky";
        headerStyle.top = 0;
      }

      // Render the section header
      let headerView = <View style={headerStyle}>
        {renderSectionHeader({section})}
      </View>;

      // Render the item
      let itemView = renderItem({item, index, section});

      if ( index === 0 ) {
        return (
          <View key={key} ref={(comp)=>{this._mountedSection(section,comp)}}>
            {headerView}
            {itemView}
          </View>
        )
      }
      else {
        return (
          <View key={key}>
            {itemView}
          </View>
        )
      }
    };

    //             extraData={{ ...this.state, ...this.props.extraData }}


    const toggle = this.state.refreshToggle;
    //console.log("HigherSectionList:",this.state);
    return (<SectionList
             name={"sections"}
             ref={(c)=>{this._sectionList=c;}}
             renderItem={_renderItem}
             onViewableItemsChanged={(res)=>{this._reportViewableItemsChanged(res)}}
             onEndReachedThreshold={0}
             onEndReached={(res)=>{this._reportEndReached(res)}}
             onScroll={(res)=>{
               this._scrollThrottle(res);
               //this._reportScroll(res);
               if ( this.props.onScrollList ) {
                 this.props.onScrollList(res);
               }
             }}
             onLayout={(res)=>{this._reportLayout(res)}}
             {...props}
             />);


  }
}

import React from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity, Linking } from 'react-native';

class FooterItem extends React.Component {

  handlePress() {
    if ( this.props.item.url ) {
      Linking.canOpenURL(this.props.item.url).then(supported => {
            if (supported) {
              Linking.openURL(this.props.item.url);
            }});
    }
  }

  render() {

    // How colorful is it
    const symbolImage = `https://res.cloudinary.com/higher-design/image/upload/v1551630385/symbols/${this.props.item.symbol}.png`;

    // Return it now
    return (
      <TouchableOpacity onPress={() => { this.handlePress(); }}>
        <View style={styles.item}>
          <Image style={styles.symbol} source={{ uri: symbolImage }}/>
          <Text style={styles.text}>
            {this.props.item.text}
          </Text>
        </View>
      </TouchableOpacity>
    );
  }
};

const styles = StyleSheet.create({
  item: {
    flex: 1,
    flexDirection: "row",
  },
  symbol: {
    width: 24,
    height: 24,
    lineHeight: 24,
    marginRight: 10,
  },
  text: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: "400",
    fontFamily: 'System',
  },
});

export default FooterItem;

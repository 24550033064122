import React from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  Dimensions,
  StyleSheet,
  FlatList,
  Animated,
} from 'react-native';
import HigherVideo from '../../components/utility/HigherVideo'
import Resizable from '../../components/utility/Resizable';
//import AutoHeightImage from 'react-native-auto-height-image';

export default class FlatProject extends Resizable {

  // Track the window size
  state = { ...Dimensions.get("window") };
  componentWillMount() { this.hearResize(); }
  componentWillUnmount() { this.muteResize(); }

  /*componentDidUpdate(prevProps, prevState) {
    if (prevProps.width !== this.props.wtidth) {
      this.updateDimensions();
    }
  }*/

  // Render our project flexibly based on the JSON
  render() {

    const onLayout = (e) => {
      // log it
      console.log("OnLayout, W=" + e.nativeEvent.layout.width + ", H=" + e.nativeEvent.layout.height);
      this.setState({
        componentWidth: e.nativeEvent.layout.width,
        componentHeight: e.nativeEvent.layout.height,
        componentX: e.nativeEvent.layout.x,
        componentY: e.nativeEvent.layout.y
      });
    }

    // Render the items in our flatlist
    const renderItem = ({item, index}) => {

      // Is the item a conditional
      if ( item.if ) {
        if ( item.if === "team" ) {
          console.log("has team")
          if ( this.props.personName ) {
            return <View/>;
          }
        }
        else if ( item.if !== this.props.personName ) {
          return <View/>;
        }
      }

      // Was the prior element a bullet?
      let afterBulletStyle = {};
      if ( index > 0 ) {
        if ( this.props.content[index - 1].bullet ) {
          afterBulletStyle = { marginTop: 10 };
        }
      }

      if ( item.text ) {
        return <Text style={[styles.text, afterBulletStyle]}>{item.text}</Text>;
      }
      else if ( item.description ) {
        return <Text style={[styles.text, afterBulletStyle]}>{this.props.description}</Text>;
      }
      else if ( item.space ) {
        return <View style={{ height: item.space }}/>;
      }
      else if ( item.heading ) {
        return <Text style={styles.heading}>{item.heading}</Text>;
      }
      else if ( item.bullet ) {
        return <Text style={styles.bullet}>• {item.bullet}</Text>;
      }
      else if ( item.link && item.url ) {
        return <TouchableOpacity style={[styles.text, styles.link, 
                                        afterBulletStyle, 
                                        {color: item.color || '#ff2187'}]} 
                     onPress={() => window.open(item.url, '_blank')}>
                 {item.link}
               </TouchableOpacity>;
      }
      else if ( item.photo ) {

        // Compute width
        //let width = (this.state.width < 700) ? this.state.width : this.state.componentWidth;

/*        // Add an optional right margin
        if ( item.marginRight ) {
          width -= item.marginRight;
        }

        // Enforce an optional max width
        if ( !this.props.mobile && item.maxWidth ) {
          width = Math.min(width, item.maxWidth);
        }*/

        // Compute height
        let height = Animated.multiply(this.props.width, 1/item.aspect);

        // Which project
        //console.log("Flat project W=" + width + ", H=" + height + ", T=" + item.text);

        // Now get our photo
        const photoUri = `https://res.cloudinary.com/higher-design/image/upload/c_scale,w_${item.resolution || 1200}/v1551630385/${item.photo}`;
        return <Animated.Image style={[styles.photo, { width: this.props.width, height: height, maxWidth: item.maxWidth, maxHeight: item.maxWidth / item.aspect, marginRight: item.marginRight, marginLeft: item.marginLeft }, afterBulletStyle]} source={{ uri: photoUri }}/>
      }
      else if ( item.video ) {
        const videoUri = `https://res.cloudinary.com/higher-design/video/upload/v1554355187/videos/${item.video}.mp4`;
        const posterUri = `https://res.cloudinary.com/higher-design/video/upload/so_0/v1554355187/videos/${item.video}.jpg`;
        return <HigherVideo style={[styles.video, afterBulletStyle]} useNativeControls source={{ uri: videoUri }} poster={posterUri}/>
      }
      else {
        return <View/>;
      }
    }

    return (
      <View style={styles.content} ref="container" onLayout={onLayout}>
        <FlatList data={this.props.content} renderItem={renderItem} extraData={{ width: this.state.width }}/>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  content: {
    paddingBottom: 25,
  },
  text: {
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 15,
    fontSize: 15,
    fontWeight: "300",
    fontFamily: 'System',
    maxWidth: 500,
  },
  link: {
    /*color: "#00aeef",*/
    color: "#ff2187",
    /*color: "blue",*/
    fontWeight: "400",
    cursor: "pointer",
  },
  heading: {
    marginLeft: 15,
    marginRight: 15,
    marginTop: 10,
    marginBottom: 15,
    fontSize: 15,
    fontWeight: "600",
    fontFamily: 'System',
  },
  bullet: {
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 5,
    fontSize: 15,
    fontWeight: "300",
    fontFamily: 'System',
  },
  video: {
    width: "100%",
    height: "auto",
    marginTop: 5,
    marginBottom: 20,
  },
  photo: {
/*    flex: 1,
    alignSelf: 'stretch',*/
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    /*resizeMode: "contain",*/
  },
});

/*
<HigherVideo controls src="https://res.cloudinary.com/higher-design/video/upload/v1554355187/videos/textblade-720.mp4"/>
*/

export default {
  name: "fernando",
  image: "fernando",
  url: "/fernando",
  firstName: "Fernando",
  lastName: "Mora",
  title: "Cloud Architect",
  categories: ["cloud", "app", "web", "hardware"],
  projects: [["upgift-app", "upgift-website", "upgift-payments", "upgift-social-network", "upgift-search",],
             ["textblade-keyboard", "textblade-app", "textblade-website", "textblade-order-system",  "remote-scopes",  "realtime-device-sync", "fingerbot", "forcebot"],
             ["nextengine-scanner", "scanstudio", "nextengine-website", "nextengine-comparo", "nextengine-order-system", "nextwiki", "nextengine-licensing"],
           ],
  shortDescription: "Over 13 years of professional experience.",
//description: "Lorem ipsum dolor fugis epitat\nipsum dolor epitat vidi vini vicci.",
  description: "Lorem ipsum dolor fugis epitat\nipsum dolor epitat vidi vini vicci.",
  //description: "I love to design and build applications\nthat are powerful, highly useful, and simple to maintain.",
  description: "I love to design and build applications\nthat are powerful yet simple to maintain.",
  menu: {
    index: {
      top: {
        name: "top",
        title: "FERNANDO"
      },
      portfolio: {
        name: "portfolio",
        title: "PORTFOLIO"
      },
      cv: {
        name: "cv",
        title: "CV",
      },
      skills: {
        name: "skills",
        title: "SKILLS",
      },
      about: {
        name: "about",
        title: "ABOUT",
      },
      talk: {
        name: "talk",
        title: "TALK",
      },
    },
    sequence: ["top", "portfolio", "cv", "skills", "about", "talk"]
  },
  footer: {
    columnWidth: 250,
    index: {
      copyright: {
          symbol: "copyright",
          text: "2019 Fernando Mora",
      },
      location: {
          symbol: "location",
          text: "Los Angeles, CA",
      },
      location: {
          symbol: "linkedin",
          url: "https://www.linkedin.com/in/morafernando/",
          text: "Connect on LinkedIn",
      },
      email: {
          symbol: "email",
          url: "mailto:fernandomora@gmail.com",
          text: "fernandomora@gmail.com",
      },

    },
    sequence: ["location", "email"]
  },
  about: {
    paragraphs: [
      "I’m a 10+ year software engineer veteran. I love to work on difficult problems, enjoy learning from others, and am not afraid to tackle challenges in unfamiliar areas.",
      "My experience has given me the opportunity to work on projects ranging from server hardware deployments to Serverless cloud architecture design.",
      "With high reliability in mind, I’ve developed both the front-end and back-end portions of applications that are efficient, scalable, and simple to maintain.",
    ],
  },
  talk: {
    email: "fernandomora@gmail.com",
    button: "Yes, Let's Connect!",
    paragraphs: [
      "Want to explore working together?"
    ],
  },
  jobs: {
    description: "Veteran cloud architect. 10x engineer.",
    index: {
      upgift: {
        name: "upgift",
        where: "UpGift",
        when: "2017 - PRESENT",
        project: "UpGift Experience Platform",
        role: "Lead Software Engineer",
        narrowRole: "Lead Software Engineer",
        description: "Consumer app transforms how we give to unlock the experience economy.",
        bullets: [
          [
            "Created and deployed customer facing web, iOS and Android applications.",
            "Implemented experiences search leveraging Yelp, EventBrite, local vendors.",
            "Helped design applicaton UI, user workflows, and customer experience."
          ],
          [
            "Designed, developed, and deployed API to power application.",
            "Created user permission and data access management processes.",
            "Integrated existing services and APIs to quickly develop and deploy application.",
            "Implemented back-end using Amazon Web Services native architecture of the cloud.",
          ],
          [
            "Designed database, micro services, and automated processes that fuel the platform.",
            "Created scripts to generate DB reports, execute migrations, and replicate data.",
            "Built tools to quickly deploy updates to develoment, QA, and production environments.",
          ],
        ],
      },
      waytools: {
        name: "waytools",
        where: "WayTools",
        when: "2011 - 2017",
        project: "TextBlade Multi-Touch Keyboard",
        role: "Senior Software Engineer",
        narrowRole: "Senior Software Engineer",
        description: "Full QWERTY keyboard, packs to 1/3rd size of iPhone.  Magnetic suspension +  luxurious key feel.",
        bullets: [
          [
            "Developed customer facing TextBlade iOS app.",
            "Developed company website, order system, factory software, cloud systems.",
            "Created advanced diagnostic software, including remote oscilliscope + flight recorder.",
            "Designed and built system for communicating with customers and collecting detailed logs.",
          ],
          [
            "Designed LiveDoc cloud database with real-time syncing, redundancy, ability to scale at low cost.",
            "Designed fault-tolerant, encrypted protocol between firmware + iOS + cloud for reliable OTA updates.",
            "Managed weekly roll-out of OTA firmware updates to customers in the field.",
            "Designed FingerBot, an automated QA robot + an iOS app to operate a fleet of QA robots.",
          ],
          [
            "Designed password-free process to allow fast and secure user application authentication.",
            "Created system to quickly develop, test, and deploy application updates.",
            "Help develop engines to easily implement + release customer facing UIs."
          ]
        ],
      },
      nextengine: {
        name: "nextengine",
        where: "NextEngine",
        when: "2007 - 2011",
        project: "NextEngine Desktop 3D Scanner",
        role: "Software Engineer",
        narrowRole: "Software Engineer",
        description: "Best-selling 3D scanner in world. Used in 90 + countries for science, manufacturing, education, film.",
        bullets: [
          [
            "Built real-time customer support into app, so customers + staff experts could collaborate on 3D scans.",
            "Architected cloud systems for log capture, user / device analytics, and OTA updates.",
            "Designed secure automated Software licensing system using public key cryptography.",
            "Implemented app version auto-update management system to control software releases."
          ],
          [
            "Planned and executed migration of NextWiki support system to Amazon Web Services cloud.",
            "Deployed countinuous integration server to automate the building and testing of Scanner Application.",
            "Designed and developed highly performant online store and order management system.",
          ],
          [
            "Implemented customer self-serve software licence management system.",
            "Designed and implemented user task management and distribution tools.",
            "Deployed automated processes to provide periodic sales, employee task, and usage reports.",
          ],
        ],
      },
      cj: {
        name: "cj",
        where: "CJ Affiliate",
        when: "2006 - 2007",
        project: "Affiliate Marketing",
        role: "Jr Software Engineer",
        narrowRole: "Jr Software Engineer",
        description: "Leading affiliate marketing network that drives results for businesses around the world.",
        bullets: [
          [
            "Helped design, develop and maintain customer facing web applications.",
            "Assisted in the deployment of developed software solutions to production.",
            "Collaborated with team members using Scrum methodologies and Agile software development",
          ],
          [
            "Designed and implemented unit and integration tests to drive development.",
            "Wrote automated scripts to process and analyze live customer data.",
          ]
        ],
      },
      ucsb: {
        name: "ucsb",
        type: "education",
        where: "Education",
        when: "2002 - 2006",
        project: "UC Santa Barbara",
        role: "B.S. Computer Science",
        narrowRole: "BS Computer Science",
        bullets: [
          [
            "Tutored studens in Math, Physics, CS and ECE courses for MESA Engineering Program",
            //"Tutored students in Math, Physics, ECE (Electric Circuits and Devices), and CS (C/C++, Java, and Unix).",
            "Conducted Research for the development of efficient optical signal interpretation devices.",
            "External Vice-President of Los Ingenieros (UCSB Student Chapter SHPE).",
          ],
        ],
      },
    },
    sequence: ["upgift", "waytools", "nextengine", "cj", "ucsb"]
  },
  skills: {
    description: "Over 13 years of professional experience.",
    index: {
      design: {
        name: "design",
        title: "Design",
        bullets: [
          "Serverless Architecture",
          "Cloud Architecture",
          "App Architecture",
          "API Design",
          "Protocol Design",
          "Database Design",
          "DB Query Optimization",
          "Secure Payment Systems",
          "PCI Compliant Systems",
          "Realtime Collaboration Systems",
        ],
      },
      tools: {
        name: "tools",
        title: "Tools",
        bullets: [
          "Serverless Framework",
          "Amazon AWS",
          "Amazon DynamoDB",
          "Amazon Lambda",
          "Amazon Cloudfront",
          "Xcode",
          "Android Studio",
          "Adobe Photoshop",
          "Atom",
          "Git",
        ],
      },
      code: {
        name: "code",
        title: "Code",
        bullets: [
          "React",
          "React Native",
          "Objective-C",
          "HTML / CSS",
          "JavaScript",
          "Node.js",
          "Ruby",
          "Java",
          "C++",
          "C",
        ],
      },
      lead: {
        name: "lead",
        title: "Leadership",
        bullets: [
          "Humility",
          "Empathy",
          "Love of Learning",
          "Boundless Curiosity",
          "Consensus Building",
          "Active Listening",
          "Product Management",
          "Plans for Extensibility",
          "Mentors Teammates",
          "Calm under Fire",
        ],
      },
    },
    sequence: ["design", "tools", "code", "lead"],
  },
};

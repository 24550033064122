import React from 'react';
import { Text, View } from 'react-native';
import ColorButton from './ColorButton';
import { connect } from 'react-redux';

class ConnectButton extends React.Component {

  onPress() {
    window.gtag('event', "Email", { 'event_category': "Person", 'event_label': this.props.person });
    window.location = `mailto:${this.props.email}`;
  }

  render() {
    return (
      <ColorButton width={this.props.width} text={this.props.text || "Yes, Let's Connect!"} backgroundColor={ this.props.backgroundColor || "#AA39FD"} onPress={() => { this.onPress(); } }/>
    );
  }
};

const childProps = (state, parentProps) => ({
  ...parentProps,
});

const childDispatch = (dispatch) => {
    return {
    };
};

export default connect(
  childProps,
  childDispatch
)(ConnectButton);

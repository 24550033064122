import React from 'react';
import { View } from 'react-native';

export default class HigherGradient extends React.Component {
  render() {
    if ( this.props.colors ) {
      const colors = this.props.colors.join(", ");
      return (
        <View style={{ ...this.props.style, background: `linear-gradient(${this.props.direction}, ${colors})` }}>
          {this.props.children}
        </View>);
    }
    else {
      return (
        <View style={{ ...this.props.style, background: `linear-gradient(${this.props.direction}, ${this.props.from}, ${this.props.to})` }}>
          {this.props.children}
        </View>);
    }
  }
}

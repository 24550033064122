import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

class JobBulletItem extends React.Component {

  render() {

    // Return it now
    return (
      <Text style={{ fontSize: 15, fontWeight: "400", fontFamily: 'System', marginTop: this.props.narrow ? 10 : 0, marginBottom: 2 }}>
        • {this.props.bullet}
      </Text>
    );
  }
};

export default JobBulletItem;

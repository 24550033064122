// src/Home.js
import React from 'react';
import { Animated, FlatList, Dimensions, Image, Easing, Platform, StyleSheet, Text, View, ScrollView, TouchableWithoutFeedback, Modal, Alert, TouchableHighlight } from 'react-native';
import CloseButton from '../buttons/CloseButton.js'
import JobItem from '../items/JobItem';
import ViewResumeButton from '../buttons/ViewResumeButton';
import DownloadResumeButton from '../buttons/DownloadResumeButton.js';
import PEOPLE from '../../constants/people'
import { connect } from 'react-redux'
import { chooseCard } from '../../state/actions/cardActions.js'
//import { Haptic } from 'expo';

class ResumeCard extends React.Component {

  // Used for animations on press
  state = { ...Dimensions.get("window"),
            pressed: false,
            scale: new Animated.Value(1),
            padding: new Animated.Value(0),
            visibility: new Animated.Value(0) }

  // Update our dimensions now
  updateDimensions() {
    this.setState(Dimensions.get('window'));
  }

  componentWillMount() {
    if ( Platform.OS == "web" ) {
      window.addEventListener("resize", () => { this.updateDimensions() });
    }
    else {
      Dimensions.addEventListener("change", () => { this.updateDimensions() });
    }
  }

  componentWillUnmount() {
    if ( Platform.OS == "web" ) {
      window.removeEventListener("resize", () => { this.updateDimensions() });
    }
    else {
      Dimensions.removeEventListener("change", () => { this.updateDimensions() });
    }
  }

  handlePress() {

    // Is it the narrow version
    const narrow = this.state.width < 950;
    if ( narrow ) {

      // Only accept presses if we're not expanded already
      if ( !this.props.expanded ) {
        if ( this.refs.container ) {
          this.refs.container.measure( (fx, fy, width, height, px, py) => {

            // Let's setup a new card that occupies that position
            // and then we'll expand the card from that position
            // to fill the entire screen.
            this.refs.container.measureInWindow( (windowX, windowY, windowWidth, windowHeight) => {

              // Let the state know to store this card position
              this.props.chooseCard(this.props.person.name, "resume", px, windowY, width, height);
            });
          })
        }
      }
    }
  }

  // Pass on the closing event
  onClose() {
    if ( this.props.expanded ) {
      if ( this.props.onClose ) {
        this.props.onClose();
      }
    }
    else {
      this.handlePress();
    }
  }

  render() {

    // Is it the narrow version
    const narrow = this.state.width < 950;

    // Are we supposed to show buttons
    let buttons = <View/>;
    if ( narrow ) {
      if ( this.props.expanded ) {
        buttons = <View style={styles.buttonsNarrowExpanded}>
                    <View style={styles.button}><DownloadResumeButton person={this.props.person.name} text="Download" width={200} backgroundColor="#00AEEF"/></View>
                  </View>
      }
      else {
        buttons = <View style={styles.buttonsNarrow}>
                    <View style={styles.button}><ViewResumeButton backgroundColor="#21D078" onPress={() => { this.handlePress(); }}/></View>
                    <View style={styles.buttonSeparator}/>
                    <View style={styles.button}><DownloadResumeButton person={this.props.person.name} backgroundColor="#00AEEF"/></View>
                  </View>
      }
    }
    else {
      buttons = <View style={styles.buttonsWide}>
                  <View style={styles.button}><DownloadResumeButton person={this.props.person.name} text="Download" width={220} backgroundColor="#00AEEF"/></View>
                </View>
    }


    // Figure out padding and border aridus
    let padding = 0;
    if ( this.props.expansion ) {
      padding = this.props.expansion.interpolate({ inputRange: [0, 1], outputRange: [0, 40] });
    }

    // Figure out animation sizes
    const minCardWidth = this.state.width;
    const minCardHeight = this.props.height;
    const cardWidth = Animated.add(minCardWidth - 40, padding);
    const cardHeight = Animated.add(minCardHeight, padding);
    const cardHorizontalMargin = Animated.add(20, Animated.multiply(-0.5, padding));
    const cardVerticalMargin = Animated.add(20, Animated.multiply(-0.5, padding));

    let cardStyle = {
      width: cardWidth,
      height: this.props.expanded ? cardHeight : undefined,
      marginLeft: cardHorizontalMargin,
      marginRight: cardHorizontalMargin,
      marginTop: cardVerticalMargin,
      marginBottom: cardVerticalMargin,
      borderRadius: this.props.radius,
      zIndex: this.state.pressed ? 100 : 1
    };

    // Is an expansion passed in
    // expansion.interpolate({ inputRange: [0, 1], outputRange: [160, 300] });
    let expandedContent = <View/>;
    if ( this.props.expanded ) {
      expandedContent = <View>{this.props.children}</View>;
    }

    // Lookup the person
    const resume = PEOPLE.index[this.props.person.name] || {};
    const jobs = resume.jobs || { index: {}, sequence: [] };

    // This is the content of the card
    const content = <View style={styles.cardContent}>
                        <FlatList
                          style={{  }}
                          keyExtractor={job => job.name}
                          data={jobs.sequence.map(jobName => jobs.index[jobName])}
                          renderItem={({ item }) => (
                            <View><JobItem job={item}
                                           narrow={narrow}
                                           expanded={this.props.expanded}/></View>
                          )}
                        />
                        {buttons}
                    </View>;

    // Our scrollable area
    let scrollable = content;
    if ( this.props.expanded ) {
      scrollable = <Animated.ScrollView style={styles.scroll}>{content}</Animated.ScrollView>;
    }

    // Is this thing going to be touchable?
    let resumeCard = <Animated.View style={[styles.card, cardStyle]}>
                        <Animated.View style={[{ opacity: this.props.expanded ? this.props.expansion : 0 },
                                               this.props.fullScreen ? styles.closeButtonFullScreen : styles.closeButton]}>
                          <CloseButton light thickness={0.17} size={1} onClose={() => { this.onClose(); }}/>
                        </Animated.View>
                        {scrollable}
                     </Animated.View>;

    // Do we make it touchable?
    if ( narrow ) {

      // Return it now
      return (
        <View ref="container" style={{  }}>
        <TouchableWithoutFeedback
          onPressIn={ () => {
            this.setState({ pressed: true });
          }}
          onPressOut= { () => {
            this.setState({ pressed: false });
          }}
          onPress={ () => { this.handlePress(); } }>
            {resumeCard}
          </TouchableWithoutFeedback>
        </View>
      );
    }
    else {
      return (<View ref="container" style={{  }}>
        {resumeCard}
      </View>);
    }
  }
};

const childProps = (state, parentProps) => ({
  ...parentProps,
});

const childDispatch = (dispatch) => {
    return {
        chooseCard: (name, type, x, y, width, height) => dispatch(chooseCard(name, type, x, y, width, height)),
    };
};

export default connect(
  childProps,
  childDispatch
)(ResumeCard);

const styles = StyleSheet.create({
  card: {
    backgroundColor: "white",
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    borderRadius:10,
    shadowRadius: 20,
    elevation: 5,
    marginLeft: 20,
    marginRight: 20,
    paddingTop: 10,
  },
  cardContent: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  posterWrapper: {
    width: "100%",
    height: 170,
    overflow: "hidden",
    borderRadius: 10,
    marginBottom: -10
  },
  poster: {
    width: "100%",
    height: 160,
  },
  header: { flexDirection: "row", marginTop: 15, marginBottom: 15, marginLeft: 15, },
  headerImage: { width: 60, height: 42 },
  headerText: { marginLeft: 10 },
  title: { marginTop: 10, fontSize: 15, marginRight: 15, fontWeight: "600", fontFamily: 'System' },
  subtitle: { marginTop: 5, fontSize: 15, marginRight: 15, fontWeight: "300", fontFamily: 'System' },
  hairline: { marginTop: 0, marginLeft: 15, marginRight: 15, height: 1, backgroundColor: "#E7E7E7"},
  description: { width: 290 - 30, marginTop: 15, marginLeft: 15, marginRight: 15, fontSize: 15, fontWeight: "300", fontFamily: 'System' },

  entry: {
    height: 50,
    width: "100%",
    flexDirection: "row",
  },
  meter: {
    marginTop: 15,
    marginLeft: 15,
    marginRight: 15
  },
  bullet: {
    marginRight: 5,
  },
  content: {
    width: "100%",
    borderBottomWidth: 1,
    borderColor: "#F4F4F4",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  contentLast: {
    width: "100%",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  title: {
    fontSize: 16,
    fontWeight: "600",
    textAlign: "left",
    letterSpacing: -0.5,
    marginTop: 0,
    width: 230
  },
  extra: {
    fontSize: 14,
    color: "rgba(0,0,0,0.30)",
    letterSpacing: -0.25,
    marginTop: 17,
    textAlign: "right",
    marginRight: 25
  },
  closeButton: {
    position: "absolute",
    top: 15,
    right: 15,
    zIndex: 10
  },
  closeButtonFullScreen: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 10
  },
  scroll: {
  },
  buttonsWide: {
    margin: "0 auto",
    alignSelf: "center",
    justifyContent: "center",
    textAlign: "center",
    marginTop: 15,
    marginBottom: 25,
  },
  buttonsNarrowExpanded: {
    margin: "0 auto",
    alignSelf: "center",
    justifyContent: "center",
    textAlign: "center",
    marginTop: 10,
    marginBottom: 65,
  },
  buttonsNarrow: {
    flexDirection: "row",
    justifyContent: "center",
    marginLeft: 20,
    marginRight: 20,
    marginTop: 10,
    marginBottom: 20,
    height: 50,
    maxWidth: 300
  },
  buttonSeparator: {
    width: 15,
  },
  button: {
    flex: 1,
  }
});

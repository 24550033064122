import React from 'react';
import { Text, View } from 'react-native';
import ColorButton from './ColorButton';
import { connect } from 'react-redux';

class DownloadResumeButton extends React.Component {

  onPress() {
    window.gtag('event', "Download", { 'event_category': "Resume", 'event_label': this.props.person });
    window.open(this.props.url, "_blank");
  }

  render() {
    return (
      <ColorButton width={this.props.width} text={this.props.text || "Download PDF"} backgroundColor={ this.props.backgroundColor || "#AA39FD"} onPress={() => { this.onPress(); } }/>
    );
  }
};

const childProps = (state, parentProps) => ({
  ...parentProps,
});

const childDispatch = (dispatch) => {
    return {
    };
};

export default connect(
  childProps,
  childDispatch
)(DownloadResumeButton);

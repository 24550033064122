// Credit: https://underscorejs.org
// Reference: https://underscorejs.org/underscore.js

const _ = {};

// A (possibly faster) way to get the current timestamp as an integer.
_.now = Date.now || function() {
 return new Date().getTime();
};

// Returns a function, that, when invoked, will only be triggered at most once
// during a given window of time. Normally, the throttled function will run
// as much as it can, without ever going more than once per `wait` duration;
// but if you'd like to disable the execution on the leading edge, pass
// `{leading: false}`. To disable execution on the trailing edge, ditto.
export const throttle = function(func, wait, options) {
  var timeout, context, args, result;
  var previous = 0;
  if (!options) options = {};

  var later = function() {
    previous = options.leading === false ? 0 : _.now();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = args = null;
  };

  var throttled = function() {
    var now = _.now();
    if (!previous && options.leading === false) previous = now;
    var remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };

  throttled.cancel = function() {
    clearTimeout(timeout);
    previous = 0;
    timeout = context = args = null;
  };

  return throttled;
};

// Converts a cammel case string into a dashed string
export const camelToDashed = function(camel) {
  if (!camel) { return camel; }
  camel = camel.replace(/^[A-Z]/,m=>m.toLowerCase());
  return camel.replace(/[A-Z]/g, m => "-" + m.toLowerCase());
}

// Traverse each key of the given object
export const eachKey = function(obj, cb) {
  if (!obj || !cb) { return; }
  let keys = Object.keys(obj);
  let len = keys ? keys.length : 0;
  for (var i = 0; i < len; i++) {
    let key = keys[i];
    if (cb(key) === false) {
      break;
    }
  }
}

// Traverse each pair of the given object
export const eachPair = function(obj, cb) {
  if (!cb) { return; }
  eachKey(obj,(key)=>{return cb(key,obj[key]);});
}

// Performs shallow merge
// Creates new object
// Merges values from obj2 into obj1
export const merge = function(obj1, obj2) {
  if (!obj1 || !obj2) { return; }
  let base = {...obj1};
  eachPair(obj2,(key, val)=>{
    base[key] = val;
  });
  return base;
}

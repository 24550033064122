import React from 'react';
import { StyleSheet, Text, View, FlatList } from 'react-native';
import SkillItem from '../../components/items/SkillItem';

class SkillSet extends React.Component {

  render() {

    // Return it now
    return (
      <View style={{ marginTop: 10, marginBottom: 20 }}>
        <Text style={{ textAlign: "left", marginBottom: 3, fontSize: 18, fontWeight: "700", fontFamily: 'System' }}>{this.props.skill.title}</Text>
        {this.props.skill.bullets.map((bullet) => {
          return <SkillItem narrow={this.props.narrow} bullet={bullet}/>;
        })}
      </View>
    );
  }
};

export default SkillSet;

import React from 'react';
import {
  View,
  Text,
  Pressable,
  Platform,
  Animated,
  Easing,
  Dimensions,
  Image,
  ScrollView
} from 'react-native';
import { connect } from 'react-redux'
import { closeCard } from '../../state/actions/cardActions.js'
import ProjectCard from './ProjectCard';
import ResumeCard from './ResumeCard';
import Resizable from '../utility/Resizable';
import PROJECTS from '../../constants/projects';
import PEOPLE from '../../constants/people';

class ActiveCard extends Resizable {

  // Used for animations on press
  state = {
    expansion: new Animated.Value(0),
    opacity: new Animated.Value(0),
    width: 0,
    height: 0,
    show: false,
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    this.muteResize();
  }

  componentDidMount() {
    this.hearResize();
    if ( Platform.OS == "web" ) {
      window.addEventListener("resize", this.updateDimensionsNowAndSoon);
      window.addEventListener("orientationchange", this.updateDimensionsSoon);
    }
    if ( this.props.activeCard.name && this.props.activeCard.active ) {
      this.state.opacity.setValue(1);
      this.state.expansion.setValue(1);
    }
    else {
      this.state.opacity.setValue(0);
      this.state.expansion.setValue(0);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if ( this.props.activeCard.name != prevProps.activeCard.name ||
         this.props.activeCard.active != prevProps.activeCard.active ) {
      if ( this.props.activeCard.name && this.props.activeCard.active ) {

        // Show it and crank opacity all the way up
        this.setState({ show: true });
        this.state.opacity.setValue(1);

        // Now zoom the size
        Animated.timing(                     // Animate over time
             this.state.expansion,            // The animated value to drive
             {
               toValue: 1,                   // Animate to full color
               duration: 375,                // Make it take a while
               easing: Easing.ease,
               useNativeDriver: true,
             }
           ).start();
      }
      else {
        Animated.parallel([
          Animated.timing(
            this.state.opacity,
            {
              toValue: 0,
              delay: 300,
              duration: 175,
              useNativeDriver: true,
            }
          ),
          Animated.timing(                     // Animate over time
               this.state.expansion,            // The animated value to drive
               {
                 toValue: 0,                   // Animate to full color
                 duration: 375,                // Make it take a while
                 easing: Easing.ease,
                 useNativeDriver: true,
               }
          )
        ]).start(() => {
          this.setState({ show: false });
        });
      }
    }
  }

  onClose() {

    // Leave the card
    //this.props.history.push(`/`);
    this.props.closeCard();
  }

  render() {

    // Get our dimensions of the screen
    const windowSize = { width: this.state.width, height: this.state.height }

    // Set the categories
    if ( Platform.OS == "web" ) {
    }

    // There's a maximum and minimum size we go to as we expanded
    const maxWidth = 1000;
    let cappedX = 0;
    let cappedY = 0;
    let cappedHeight = windowSize.height;
    let cappedWidth = windowSize.width;
    let cappedRadius = 0;
    if ( cappedWidth > maxWidth ) {
      cappedWidth = maxWidth - 160;
      cappedX = (windowSize.width - cappedWidth) / 2.0;
      cappedY = 30;
      cappedRadius = 10;
      cappedHeight = windowSize.height - 30;
    }
    else {
      cappedWidth = cappedWidth - 19;
    }

    // Figure out left coordinate
    const cardX = this.state.expansion.interpolate({ inputRange: [0, 1], outputRange: [this.props.activeCard.x, cappedX] });
    const cardY = this.state.expansion.interpolate({ inputRange: [0, 1], outputRange: [this.props.activeCard.y, cappedY] });
    const cardWidth = this.state.expansion.interpolate({ inputRange: [0, 1], outputRange: [this.props.activeCard.width, cappedWidth] });
    const cardHeight = this.state.expansion.interpolate({ inputRange: [0, 1], outputRange: [this.props.activeCard.height, cappedHeight] });
    const cardRadius = this.state.expansion.interpolate({ inputRange: [0, 1], outputRange: [cappedRadius, cappedRadius] });
    const backgroundOpacity = this.state.expansion.interpolate({ inputRange: [0, 1], outputRange: [0, 0.5]})

    // Setup a dark background
    let background = <View/>;
    if ( windowSize.width > maxWidth ) {
      background = <Pressable
                      onPressIn={ () => {
                      }}
                      onPressOut= { () => {
                      }}
                      onPress={ () => { this.onClose(); } }>
                      <Animated.View style={{ position: "absolute",
                                              top: 0,
                                              left: 0,
                                              width: windowSize.width,
                                              height: windowSize.height,
                                              backgroundColor: "black",
                                              opacity: backgroundOpacity }}>
                      </Animated.View>
                    </Pressable>
    }


          /*

          <Image style={{ width: "100%", height: "100%"}}
                 resizeMode='cover'
                 source={""}
                 blurRadius={5}/>
*/

    // Show we show a card
    if ( this.state.show ) {

      console.log("Showing card ");
      console.log(this.props.activeCard);

      let card = <View/>;
      if ( this.props.activeCard.type === "resume" ) {
        let personInfo = PEOPLE.index[this.props.activeCard.name];
        card = <ResumeCard onClose={() => { this.onClose(); }}
                           person={personInfo}
                           expanded
                           expansion={this.state.expansion}
                           fullScreen={windowSize.width <= maxWidth}
                           width={cardWidth}
                           height={cardHeight}
                           radius={cardRadius}>
               </ResumeCard>
      }
      else if ( this.props.activeCard.type === "about" ) {
        let personInfo = PEOPLE.index[this.props.personName];
        if ( personInfo.about && personInfo.about.cards && personInfo.about.cards.index ) {
          let projectInfo = personInfo.about.cards.index[this.props.activeCard.name];
          card = <ProjectCard onClose={() => { this.onClose(); }}
                         card={projectInfo}
                         expanded
                         personName={this.props.personName}
                         expansion={this.state.expansion}
                         fullScreen={windowSize.width <= maxWidth}
                         width={cardWidth}
                         height={cardHeight}
                         radius={cardRadius}>
                       </ProjectCard>
        }
      }
      else {
        let projectInfo = PROJECTS.index[this.props.activeCard.name];
        card = <ProjectCard onClose={() => { this.onClose(); }}
                       card={projectInfo}
                       expanded
                       personName={this.props.personName}
                       expansion={this.state.expansion}
                       fullScreen={windowSize.width <= maxWidth}
                       width={cardWidth}
                       height={cardHeight}
                       radius={cardRadius}>
                     </ProjectCard>
      }

      return (
        <View style={{ position: ( Platform.OS == "web" ) ? "fixed" : "absolute", zIndex: 1000, left: 0, top: 0 }}>
          {background}
          <Animated.View style={{ position: "absolute", left: cardX, top: cardY, opacity: this.state.opacity }}>
            {card}
          </Animated.View>
        </View>);
    }
    else {
      return <View/>;
    }
  }
};

const childProps = (state, parentProps) => ({
  ...parentProps,
  activeCard: state.card,
});

const childDispatch = (dispatch) => {
    return {
      closeCard: () => dispatch(closeCard()),
    };
};

export default connect(
  childProps,
  childDispatch
)(ActiveCard);

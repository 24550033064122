import React from 'react';
import {
  View,
  Dimensions
} from 'react-native';

export default class FullScreenBase extends React.Component {
  nudge() {
    // Nothing by default
  }
}

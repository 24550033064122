export default {
  home: {
    columnWidth: 205,
    index: {
      copyright: {
          symbol: "copyright",
          text: "2019 Higher Design",
      },
      location: {
          symbol: "location",
          text: "San Francisco, CA",
      },
      dribbble: {
          symbol: "dribbble",
          text: "Follow us on Dribbble",
      },
      email: {
          symbol: "email",
          text: "hi@higher.design",
      },
    },
    sequence: ["copyright", "location", /*"dribbble", "email"*/]
  }
}

//             ["nextengine-scanner", "scanstudio", "nextwiki", "nextengine-website", "nextengine-comparo", "nextengine-order-system", "rapidworks", "nextengine-licensing"],

export default {
  "magbak-case": {
    name: "magbak-case",
    image: "magbak/magbak-red-gradient",
    theme: "light",
    aspectRatio: 0.55,
    title: "MagBak Case",
    subtitle: "Hardware",
    /*button: { text: "Demo", video: "nextengine/jay-leno-demo" },*/
    button: { text: "View", url: "https://1lss.com/" },
    categories: ["hardware"],
    searchOrder: 3,
    description: "A minimalist protective case and patented magnetic mounting solution for smartphones.  Users can instantly attach their phone to their car, kitchen, or anywhere.",
    //render: () => { return <TextBladeProject/> },
    content: [
      { description: true },
    ],
  },
  "magbak-wallet": {
    name: "magbak-wallet",
    image: "magbak/magbak-wallet-stand-typing",
    /*    image: "magbak/magbak-wallet-hand-loop",
*/
    theme: "light",
    aspectRatio: 0.55,
    title: "MagBak Wallet",
    subtitle: "Hardware",
    /*button: { text: "Demo", video: "nextengine/jay-leno-demo" },*/
    button: { text: "View", url: "https://1lss.com/products/magbak-wallet" },
    categories: ["hardware"],
    searchOrder: 3,
    description: "Streamlined leather wallet magnetically auto-aligns and securely attaches to smartphone. Acts as a phone stand and finger loop, and includes RFID protection.",
    content: [
      { description: true },
      { photo: "projects/magbak/magbak-wallet-hand-loop.jpg", aspect: 2048 / 1108 },
      /*{ photo: "projects/magbak/magbak-wallet-stand-typing.jpg", aspect: 2048 / 1108 },*/
    ],
  },
  "magbak-charger": {
    name: "magbak-charger",
    image: "magbak/magbak-charger-wireless2",
    /*    image: "magbak/magbak-wallet-hand-loop",
*/
    theme: "light",
    aspectRatio: 0.55,
    title: "MagBak Wireless Charger",
    subtitle: "Hardware",
    /*button: { text: "Demo", video: "nextengine/jay-leno-demo" },*/
    //button: { text: "View", url: "https://1lss.com/" },
    categories: ["hardware"],
    searchOrder: 3,
    description: "Coming soon! Top-secret new device makes high speed charging a magical experience. This novel solution outflanks key challenges of inductive charging physics.",
    content: [
      { description: true },
      /*{ photo: "projects/magbak/magbak-wallet-stand-typing.jpg", aspect: 2048 / 1108 },*/
    ],
  },

  "magbak-patent-self-align": {
    name: "magbak-patent-self-align",
    image: "magbak/magbak-patent-self-align",
    theme: "light",
    title: "Self Aligning Mount",
    subtitle: "US Patent 10134517",
    categories: ["patent"],
    extraSearchCategories: ["hardware"],
    button: { text: " PDF ", url: "/patents/US10134517.pdf" },
    description: "Smartphone self-aligns with the mount thanks to a set of opposing magnets attached to the smartphone and mounting surface. This creates a repeatable, satisfying experience.",
    content: [
      { description: true, },
    ],
  },

  "magbak-patent-low-profile": {
    name: "magbak-patent-low-profile",
    image: "magbak/magbak-patent-low-profile",
    theme: "light",
    title: "Low Profile Mount",
    subtitle: "US Patent 9704632",
    categories: ["patent"],
    extraSearchCategories: ["hardware"],
    button: { text: " PDF ", url: "/patents/US9704632.pdf" },
    description: "This patent explores a low-profile case for smartphone or tablets with multiple embedded magnets. Devices can become instantly mountable while remaining streamlined and thin.",
    content: [
      { description: true, },
    ],
  },






};

// src/Home.js
import React from 'react';
import { Animated, Easing, Platform, StyleSheet, Text, Image, View, Pressable } from 'react-native';
import { connect } from 'react-redux'
import { chooseMenu } from '../../state/actions/menuActions.js'
//import { Haptic } from 'expo';

class MenuItem extends React.Component {

  // Used for animations on press
  state = {
    pressed: false,
    fontSize: new Animated.Value(15)
  }

  componentDidMount() {
  }

  // Auto scroll to bottom after extending
  componentDidUpdate(prevProps, prevState) {
  }

  handlePress() {

    // Check to see if menu is disabled
    if (!this.props.menuDisabled) {
      
      // Choose the menu related to this item
      //this.props.chooseMenu(this.props.menu, {debounce:500});
      this.props.chooseMenu(this.props.menu);

      // Call onPress callback
      if (this.props.onPress) {
        this.props.onPress();
      }
    }
  }

  render() {

    // It's gray if there's a selected menu and it's not us
    let gray = this.props.selectedMenuName != this.props.menu.name;

    // Is it colorful or gray
    const textColor = gray ? "#9A9A9A" : "black";

    // The maximum image scale
    const maxScale = 1.2;

    // Return it now
    return (
      <View style={{ marginLeft: 18,
                     marginRight: 18,
                     marginBottom: 15 }}>
      <Pressable
        onPressIn={ () => {
          //if ( Platform.OS === 'ios' ) { Haptic.selection(); }
          //Haptic.impact(Haptic.ImpactStyles.Light);
          this.setState({ pressed: true });
          /*Animated.timing(                  // Animate over time
               this.state.fontSize,            // The animated value to drive
               {
                 toValue: 16 * maxScale,               // Animate to size 18
                 duration: 0,              // Make it take a while
               }
             ).start();*/
        }}
        onPressOut= { () => {
          //if ( Platform.OS === 'ios' ) { Haptic.impact(Haptic.ImpactFeedbackStyle.Light); }
          this.setState({ pressed: false });
          /*Animated.timing(                   // Animate over time
               this.state.fontSize,              // The animated value to drive
               {
                 toValue: 16,                 // Animate back to small size
                 duration: 500,              // Make it take a while
                 easing: Easing.back(),
               }
             ).start();*/
        }}
        onPress={ () => { this.handlePress(); } }>
          <View>
            <View style={{ marginTop: 5 }}>
              <Animated.Text style={{ textAlign: "center", color: textColor, fontSize: this.state.fontSize, fontWeight: "700", fontFamily: 'System' }}>{this.props.menu.title}</Animated.Text>
            </View>
          </View>
        </Pressable>
      </View>
    );
  }
};

const childProps = (state, parentProps) => ({
  ...parentProps,
  selectedMenuName: state.menu.name,
  menuDisabled: state.menu.disabled === true
});

const childDispatch = (dispatch) => {
    return {
        chooseMenu: (menu, opts) => dispatch(chooseMenu(menu, opts)),
    };
};

export default connect(
  childProps,
  childDispatch
)(MenuItem);

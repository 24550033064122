import React from 'react';
import {
  View,
  Platform,
  Dimensions,
} from 'react-native';

export default class Resizable extends React.Component {

  // Used for sizing
  state = {
    width: 0,
    height: 0,
  }

  updateDimensions = () => {
    const dimensions = Dimensions.get('window');
    var viewportWidth = document.documentElement.clientWidth;
    var zoom = viewportWidth / window.innerWidth;
    this.setState({ width: dimensions.width * zoom, height: dimensions.height * zoom});
  }

  updateDimensionsSoon = () => {
    setTimeout(() => { this.updateDimensions(); }, 500);
  }

  updateDimensionsNowAndSoon = () => {
    this.updateDimensions();
    setTimeout(() => { this.updateDimensions(); }, 500);
  }

  muteResize() {
    if ( Platform.OS == "web" ) {
      window.removeEventListener("resize", this.updateDimensionsNowAndSoon);
      window.removeEventListener("orientationchange", this.updateDimensions);
    }
    else {
      Dimensions.removeEventListener("change", this.updateDimensionsNowAndSoon);
    }
  }

  hearResize() {
    if ( Platform.OS == "web" ) {
      window.addEventListener("resize", this.updateDimensionsNowAndSoon);
      window.addEventListener("orientationchange", this.updateDimensionsSoon);
    }
    else {
      Dimensions.addEventListener("change", this.updateDimensionsNowAndSoon);
    }
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    this.muteResize();
  }

  componentDidMount() {
    this.hearResize();
  }

/*  componentDidUpdate() {
    this.updateDimensions();
  }*/

};

import React from 'react';
import {
  UIManager
} from 'react-native';
import { throttle } from '../../lib/Underscore';

import FuzzyMap from '../../lib/FuzzyMap';

// The y offset threshold necessary to cound a section as reached
const REACHED_THRESHOLD_Y = 1;

// The scroll throttle interval
const SCROLL_THROTTLE = 10;

export default class HigherSectionListBase extends React.Component {

  constructor(props) {
    super(props);

    // Default state
    this.state = { refreshToggle: false };

    // View handles
    this._container=null;
    this._sectionViews={};

    // Section nav handles
    this._currSection=null;
    this._destSection=null;
    this._destSectionEpoc=null;

    // Section list layout and offset measurements
    this._layout=null;
    this._offset=null;

    // Help easily lookup a section view based on scroll location
    this._sectionMap = new FuzzyMap();

    // Layout report throttle
    this._layoutTimeout = null;

    // Throttle scrolling
    this._scrollThrottle = throttle((res)=>{
      this._reportScroll(res);
    },SCROLL_THROTTLE);
  }

  componentDidUpdate() {
    // Make sure to remap sections in case of updates
    //this._remapSections();
  }

  componentWillUnmount() {
    this._sectionMap.clear();
  }

  forceRefresh() {
    console.log("forceRefresh");
    const toggle = this.state.refreshToggle;
    this.setState({refreshToggle:!toggle},()=>{
      this._remapSections();
    });
  }

  forceListUpdate() {
    if (this._sectionList) {
      this._sectionList.forceUpdate();
      //console.log("Forcing sectionList update");
    }
  }

  scrollToLocation(opts){
    if (this._sectionList) {
      this._sectionList.scrollToLocation(opts);
    }
  }

  recordInteraction(opts){
    if (this._sectionList) {
      this._sectionList.recordInteraction(opts);
    }
  }

  scrollToSectionWithTitle(title) {
    // Lookup the index of the section with the title
    let index = this.indexOfSectionWithTitle(title);
    if (index >= 0 ) {
      this.scrollToLocation({
        sectionIndex: index,
        itemIndex: 0,
        animated: true
      });
    }
  }

  indexOfSectionWithTitle(title) {
    // Lookup the index of the section with the title
    let index = -1;
    let sections = this.props.sections;
    for (var i = 0; i < sections.length; i++) {
      let section = sections[i];
      if (section.title.toLowerCase() === title.toLowerCase()) {
        index = i;
        break;
      }
    }
    return index;
  }

  scrollToSectionWithName(name, options = {}) {
    console.log("scrollToSectionWithName:", name);

    // Lookup the index of the section with the name
    let index = this.indexOfSectionWithName(name);

    // Do nothing if index is not ofund
    if (index < 0) {
      console.log("scrollToSectionWithName: Not found!");
      return;
    }

    // Make sure scroll will occur
    if (this._offset) {

      // Do nothing if at top won't scroll
      if (index == 0  && this._offset.top == 0) {
        return;
      }

      // Do nothing if at bottom and won't scroll
      let map = this._sectionMap.atIndex(index);
      if (map) {
        let willReach = (this._offset.height - map.from) >= this._layout.height;
        if (index > 0 && !willReach && this._offset.bottom == 0) {
          return;
        }
      }
    }

    // Go to destination section (Manually)
    this._destSection = this.props.sections[index];
    this._destSectionEpoc = this.state.refreshToggle;
    this._sectionMap.normalize(); // Make sure map is normalized

    // Set this section
    this._stuckSection = this._destSection;
    if ( this.props.onStuckSectionChange ) {
      if ( this._destSection.showLineOnJump ) {
        setTimeout(() => { this.props.onStuckSectionChange(this._stuckSection); }, 50);
      }
      else {
        setTimeout(() => { this.props.onStuckSectionChange({}); }, 50);
      }
    }

    let map = this._sectionMap.atIndex(index);
    if (map) {
      this._sectionList._wrapperListRef._listRef.scrollToOffset({offset: map.from});
    }
  }

  indexOfSectionWithName(name) {
    // Lookup the index of the section with the name
    let index = -1;
    let sections = this.props.sections;
    for (var i = 0; i < sections.length; i++) {
      let section = sections[i];
      if (section.name === name) {
        index = i;
        break;
      }
    }
    return index;
  }

  _updateStuckSection(section){
    if (!section || !this._stuckSection || this._stuckSection.title != section.title) {
      if (this.props.onStuckSectionChange) { this.props.onStuckSectionChange(section); }
      this._stuckSection = section;
    }
  }

  _updateCurrSection(section){
    if (!section || !this._currSection || this._currSection.title != section.title) {
      if (this.props.onSectionChange) { this.props.onSectionChange(section); }
      this._currSection = section;
    }
  }

  _reportViewableItemsChanged(res) {
    /*
    console.log("_reportViewableItemsChanged:",res);
    // Only report viewable items if there is NO dest section
    if (!this._destSection) {
      // Get the top most viewable section
      let topItem = res.viewableItems ? res.viewableItems[0] : null;
      if (topItem) {
        // Look for section title change
        // NOTE: May not work in the case where mutliple section with same title are next to each other
        let title = topItem.section ? topItem.section.title : null;
        this._updateCurrSection(topItem.section);
      }
    }
    */
  }

  _reportEndReached(res) {
    let sections = this.props.sections;
    let lastSection = sections[sections.length-1];
    this._updateCurrSection(lastSection);
    this._updateStuckSection(lastSection);
  }

  _reportScroll({nativeEvent:{contentOffset,contentSize,layoutMeasurement}}) {

    // Calculate bottom line
    let topLine = contentOffset.y;
    let botLine = topLine+layoutMeasurement.height;

    // Return if no change is scroll
    if (this._offset && this._offset.top == topLine) {
      return;
    }

    // Update offset
    this._offset = {
      top:topLine,
      bottom:contentSize.height-botLine,
      height:contentSize.height
    };

    // Check if bottom reached
    const top = topLine <= 0 ;
    const bottom = botLine >= contentSize.height;

    // Check if jumpint to a destination
    if (this._destSection && this._destSectionEpoc === this.state.refreshToggle) {
      let key = this._keyForSection(this._destSection);
      let sectionView = this._sectionViews[key];
      if (sectionView) {
        sectionView.measure( (fx, fy, width, height, px, py) => {
          // Check if reached the dest section
          if (this._layout) {
            let diff = py-this._layout.y;
            // Check for sections that may not scroll all the way to the top
            if (Math.abs(diff) < REACHED_THRESHOLD_Y || bottom) {
              this._updateCurrSection(this._destSection);
              this._updateStuckSection(this._destSection);
              this._destSection = null; // Clear destination section
            }
          }
        });
      }
    }
    // Check which section is visible
    else {
      // Make sure dest section is cleared
      this._destSection = null;

      // Check where we are in section view
      if (top) {
        // Set first section as current
        this._updateCurrSection(this.props.sections[0]);
        this._updateStuckSection(this.props.sections[0]);
      }
      else if (bottom) {
        // Set last section as current
        let len = this.props.sections.length;
        this._updateCurrSection(this.props.sections[len-1]);
        this._updateStuckSection(this.props.sections[len-1]);
      }
      else {
        let firstSection = this._sectionMap.first();
        if (firstSection) {
          let fuzzyOffset = -firstSection.from;

          // Figure out the top location
          let stuckKey = (topLine - fuzzyOffset) - 10;
          let stuckMap = this._sectionMap.at(stuckKey);
          if (stuckMap) {
            this._updateStuckSection(stuckMap.val);
          }

          // Get the mid map
          let midKey = ((topLine+botLine)/2) - fuzzyOffset;
          let midMap = this._sectionMap.at(midKey);
          if (midMap) {
            this._updateCurrSection(midMap.val);
          }
          else {
            // Get the top map
            let topKey = topLine-fuzzyOffset;
            let topMap = this._sectionMap.at(topKey);
            if (topMap) {
              this._updateCurrSection(topMap.val);
            }
          }
        }
      }
    }
  }

  _mountedSection(section, view) {
    let key = this._keyForSection(section);
    this._sectionViews[key] = view;
  }

  // Helps generate a key for the given section
  _keyForSection(section) {
    return `${section.title}:${section.name}:${section.index}`;
  }

  _triggerLayout() {

    // Make sure to clear previous timeout
    /*if (this._layoutTimeout) {
      clearTimeout(this._layoutTimeout);
      this._layoutTimeout = null;
    }
    // Setup new timeout to handle layout
    this._layoutTimeout = setTimeout(()=>{
      this._layoutTimeout = null;
      this._remapSections();
    },500);*/

/*

    console.log("MEASURING");
    if ( this._sectionList ) {
      this._sectionList.measure( (fx, fy, width, height, px, py) => {
        console.log(`Layout Dimensions: ${fx} ${fy} ${width} ${height} ${px} ${py}`);
      });
    }*/
  }

  _reportLayout({nativeEvent}) {
    let layout = nativeEvent ? nativeEvent.layout : null;
    // Check to see if layout changed
    if (this._didLayoutChange(layout)) {
      this._layout = layout;
      // Make sure to clear previous timeout
      if (this._layoutTimeout) {
        clearTimeout(this._layoutTimeout);
        this._layoutTimeout = null;
      }
      // Setup new timeout to handle layout
      this._layoutTimeout = setTimeout(()=>{
        this._layoutTimeout = null;
        this._remapSections();
      },500);
    }
  }

  _reportSectionLayout(section, res) {
    //let key = this._keyForSection(section);
  }

  _remapSections() {
    console.log("_remapSections");
    let layout = this._layout;
    let offset = this._offset;
    this._sectionMap.clear();
    this.props.sections.forEach((section)=>{
      // Traverse the sections and map according to distance covered in scroll view
      let key = this._keyForSection(section);
      let sectionView = this._sectionViews[key];
      if (sectionView) {
        sectionView.measure( (fx, fy, width, height, px, py) => {
          let last = this._sectionMap.last();
          py = Math.floor(py);
          let from = py;
          let to = py+height;
          this._sectionMap.add(section,py,py+height);
        });
      }
    });
  }

  // Checks for a layout change
  _didLayoutChange(layout) {
    return !this._layout || (layout.width != this._layout.width ||
                             layout.height != this._layout.height ||
                             layout.x != this._layout.x ||
                             layout.y != this._layout.y);
  }

}

// Maps objects between a range of values
export default class FuzzyMap {

  constructor() {
    this._data = [];
  }

  // Raise exception when _from and _to are invalid
  add(val,from=0, to=1) {
    this._data.push({val,from,to});
    // Resort the contents
    this._data.sort((a,b)=>{
      if (a.from > b.from) {
        return 1;
      } else if (a.from < b.from) {
        return -1
      } else if (a.to > b.to) {
        return 1
      } else if (a.to < b.to) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  // Return the value at the given key
  val(key) {
    let m = this.at(key);
    return m ? m.val() : undefined;
  }

  // Return the mapped values at the given key
  at(key) {
    return this._locate(key,0,this._data.length);
  }

  // Return the mapped values at the given key
  atIndex(index) {
    return this._data[index];
  }

  // Return the first ordered item in the map
  first() {
    return this._data[0];
  }

  // Return the last ordered item in the map
  last() {
    let len = this._data.length;
    return len > 0 ? this._data[len-1] : undefined;
  }

  // Clear all of the items in the map
  clear() {
    this._data = [];
  }

  // Return the length
  length() {
    return this._data.length;
  }

  // Disambiguates entries
  disambiguateFroms() {
    let last = null;
    let len = this._data.length;
    for (var i = 0; i < len; i++) {
      let curr = this._data[i];
      if (last && curr.from < last.to) {
        curr.from = last.to+1;
      }
      last = curr;
    }
  }

  // Offset the entries
  offset(amount) {
    let len = this._data.length;
    for (var i = 0; i < len; i++) {
      let map = this._data[i];
      map.from += amount;
      map.to += amount;
    }
  }

  // Normalize the entries so that first entry starts at 0
  normalize() {
    let first = this.first();
    if (first && first.from !== 0) {
      this.offset(-first.from);
    }
  }

  // Recursively locate mapped value
  _locate(key, start, count) {
    if (count < 1) {
      return undefined;
    }
    // Get the index to check
    let off = Math.floor(count/2);
    let mid = start+off;
    let entry = this._data[mid];
    if (entry) {
      // Check bottom half of array
      if (key < entry.from) {
        return this._locate(key, start, off);
      }
      // Check top half of array
      else if (key > entry.to) {
        return this._locate(key, mid+(count%2), off);
      }
      else {
        return entry;
      }
    }
    return undefined;
  }

}

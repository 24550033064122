// src/Home.js
import React from 'react';
import { Animated, Image, Easing, Platform, StyleSheet, Text, View, ScrollView, TouchableWithoutFeedback, Modal, Alert } from 'react-native';
import CloseButton from '../buttons/CloseButton.js'
import FlatProject from '../projects/FlatProject.js'
import HigherImage from '../utility/HigherImage.js'
import OutlineButton from '../buttons/OutlineButton';
import ColorButton from '../buttons/ColorButton';
import HigherVideo from '../utility/HigherVideo'
import { connect } from 'react-redux'
import { chooseCategory } from '../../state/actions/categoryActions.js'
import { chooseCard } from '../../state/actions/cardActions.js'
import categories from '../../constants/categories';

//import { Haptic } from 'expo';

class ProjectCard extends React.Component {

  // Used for animations on press
  state = {
    pressed: false,
    scale: new Animated.Value(1),
    padding: new Animated.Value(0),
    visibility: new Animated.Value(0),
    watchingVideo: false,
    stoppingVideo: false,
    videoOpacity: new Animated.Value(0),
    animatedAspect: new Animated.Value(0.55),
  }

  shouldShowCard() {
    return !this.props.selectedCategoryName || this.props.card.categories.includes(this.props.selectedCategoryName);
  }

  componentDidMount() {
    if ( this.props.card.aspectRatio && this.props.card.aspectRatio != 0.55 ) {
      this.state.animatedAspect.setValue(this.props.card.aspectRatio);
    }
  }

  // Auto scroll to bottom after extending
  componentDidUpdate(prevProps, prevState) {
    if ( prevState.watchingVideo != this.state.watchingVideo ) {
      if ( this.state.watchingVideo ) {
        Animated.timing(                     // Animate over time
             this.state.videoOpacity,            // The animated value to drive
             {
               toValue: 1,                   // Animate to full color
               duration: 375,                // Make it take a while
               easing: Easing.ease,
               useNativeDriver: true,
             }
           ).start();
      }
      else {
        Animated.timing(                     // Animate over time
             this.state.videoOpacity,            // The animated value to drive
             {
               toValue: 0,                   // Animate to full color
               duration: 375,                // Make it take a while
               easing: Easing.ease,
               useNativeDriver: true,
             }
           ).start();
      }
    }

    // Optional aspect ratio animation
    if ( this.props.card.aspectRatio && this.props.card.aspectRatio != 0.55 ) {
      if ( prevState.watchingVideo != this.state.watchingVideo ||
           prevState.stoppingVideo != this.state.stoppingVideo ) {

        // If we're expanded and not playing a video, it can be whatever dimension we want it to be
        let targetAspectRatio = 0.55
        if ( !this.state.watchingVideo || this.state.stoppingVideo ) {
          targetAspectRatio = this.props.card.aspectRatio;
        }

        // Now do the animation
        Animated.timing(                              // Animate over time
             this.state.animatedAspect,               // The animated value to drive
             {
               toValue: targetAspectRatio,            // Animate to full color
               duration: 375,                         // Make it take a while
               easing: Easing.ease,
               useNativeDriver: true,
             }
           ).start(() => {
             this.setState({ stoppingVideo: false });
           });
      }
    }

  }

  // Animate the aspect ratio
  animateAspectRatio() {

  }

  handlePress() {

    // Only accept presses if we're not expanded already
    if ( !this.props.expanded ) {
      if ( this.refs.container ) {
        this.refs.container.measure( (fx, fy, width, height, px, py) => {

          // Let's setup a new card that occupies that position
          // and then we'll expand the card from that position
          // to fill the entire screen.
          this.refs.container.measureInWindow( (windowX, windowY, windowWidth, windowHeight) => {

            // Let the state know to store this card position
            this.props.chooseCard(this.props.card.name, this.props.card.type || "project", px, windowY, width, height);

            // Tag whenever a card gets opened
            window.gtag('event', "Open", { 'event_category': "Project", 'event_label': this.props.card.name });
          });
        })
      }

      //this.props.history.push(`/project/${this.props.card.name}`);
    }
  }

  // Pass on the closing event
  onClose() {
    if ( this.props.expanded ) {

      // Update the state
      this.setState({ watchingVideo: false, stoppingVideo: false });
      if ( this.props.onClose ) {
        this.props.onClose();
      }

      // Tag whenever a card gets closed
      window.gtag('event', "Close", { 'event_category': "Project", 'event_label': this.props.card.name });
    }
    else {
      this.handlePress();
    }
  }

  // The designated action button on the card was pushed
  onButtonPress() {
    if ( this.props.expanded ) {
      if ( this.state.watchingVideo ) {
        this.setState({ watchingVideo: false, stoppingVideo: true });
        if ( this.video ) { this.video.stop(); }
      }
      else if ( this.props.card.button ) {
        if ( this.props.card.button.url ) {
          if ( window ) {
            window.open(this.props.card.button.url, "_blank");
          }
        }
        else if ( this.props.card.button.video ) {
          this.setState({ watchingVideo: true, stoppingVideo: false })
          if ( this.video ) { this.video.play(); }

          // Tag when we play the video
          window.gtag('event', "Play", { 'event_category': "Video", 'event_label': this.props.card.name });
        }
      }
    }
  }

  onVideoEnded() {
    this.setState({ watchingVideo: false, stoppingVideo: true })
  }

  render() {

    // The maximum image scale
    const maxScale = 1;

    // See if this card should be shown
    const showCard = !this.props.selectedCategoryName || this.props.card.categories.includes(this.props.selectedCategoryName);

    // Figure out the dimensions of our images
    const thumbWidth = (this.props.card.thumb && this.props.card.thumb.resolution) ? this.props.card.thumb.resolution : 1024; /*340;*/
    const thumbHeight = Math.round(thumbWidth * (this.props.card.aspectRatio || 0.75294));

    // Figure out the height and width of the main image
    const imageWidth = this.props.card.resolution || 2048;
    const imageHeight = Math.round(imageWidth * (this.props.card.aspectRatio || 0.75294));

    // What image are we showing?
    // Iamge resize used to be `c_mpad` but this is deprecated
    const categoryNames = this.props.card.categories.join("-");
    const category = categories.index[categoryNames];
    const cardImage = `https://res.cloudinary.com/higher-design/image/upload/c_limit,h_${thumbHeight},w_${thumbWidth}/projects/${this.props.card.image}.jpg`;
    const fullCardImage = `https://res.cloudinary.com/higher-design/image/upload/c_limit,h_${imageHeight},w_${imageWidth}/projects/${this.props.card.image}.jpg`;
    const categoryImage = `https://res.cloudinary.com/higher-design/image/upload/c_limit,h_256/categories/color/${categoryNames}.png`;

    // Figure out padding and border aridus
    let padding = 0;
    if ( this.props.expansion ) {
      padding = this.props.expansion.interpolate({ inputRange: [0, 1], outputRange: [0, 20] });
    }

    // Figure out animation sizes
    const minCardWidth = this.props.width || 290;
    const minCardHeight = this.props.height || 355;
    const cardWidth = Animated.add(minCardWidth, padding);
    const cardHeight = Animated.add(minCardHeight, padding);
    const cardHorizontalMargin = Animated.add(10, Animated.multiply(-0.5, padding));
    const cardVerticalMargin = Animated.add(10, Animated.multiply(-0.5, padding));

    let cardStyle = {
      width: cardWidth,
      height: cardHeight,
      marginLeft: cardHorizontalMargin,
      marginRight: cardHorizontalMargin,
      marginTop: cardVerticalMargin,
      marginBottom: cardVerticalMargin,
      zIndex: this.state.pressed ? 100 : 1,
      cursor: this.props.expanded ? 'default' : 'pointer',
      borderRadius: this.props.radius || 10,
      transition: "border-radius 0.3s linear"
    };

    // Play with animating poster height
    let outerAspect = 0.55;
    if ( this.props.card.aspectRatio ) {
      outerAspect = (this.state.watchingVideo || this.state.stoppingVideo) ? this.state.animatedAspect : this.props.expansion.interpolate({ inputRange: [0, 1], outputRange: [0.55, this.props.card.aspectRatio] });
    }
    let posterStyle = { height: Animated.multiply(outerAspect, cardWidth), overflow: "hidden" };
    let posterWrapperStyle = { borderTopLeftRadius: this.props.radius || 10,
                               borderTopRightRadius: this.props.radius || 10,
                               height: Animated.add(posterStyle.height, 10),
                               transition: "border-radius 0.3s linear",
                               };

    // Figure out the inner aspect ratio of the image
    let innerAspect = 0.55;
    if ( this.props.card.aspectRatio ) {
      innerAspect = this.props.card.aspectRatio;
    }

    // Figure out if there is any zooming of the image
    let imageStyle = {};
    if ( this.props.card.thumb && this.props.card.thumb.zoom ) {

      // Get the left and top
      const x = this.props.card.thumb.x || 0;
      const y = this.props.card.thumb.y || 0;

      // Interpolate the zoom based on our expansion level
      const zoom = this.props.expansion.interpolate({ inputRange: [0, 1], outputRange: [this.props.card.thumb.zoom, 1] });

      // Figure out our zoomed dimensions
      const zoomedWidth = Animated.multiply(zoom, cardWidth);
      const zoomedHeight = Animated.multiply(zoomedWidth, innerAspect);
      const zoomedX = this.props.expansion.interpolate({ inputRange: [0, 1], outputRange: [-1 * x / 2, 0] });
      const zoomedY = this.props.expansion.interpolate({ inputRange: [0, 1], outputRange: [-1 * y / 2, 0] });
      const zoomedLeft = Animated.multiply(zoomedX, zoomedWidth);
      const zoomedTop = Animated.multiply(zoomedY, zoomedHeight);

      // Compute the image style for that
      imageStyle = { width: zoomedWidth, height: zoomedHeight, marginTop: zoomedTop, marginLeft: zoomedLeft };
    }

    // Figure out the style for the expanded content
    let descriptionStyle = {};
    let expandedContentStyle = {};

    // Do we have expanded content?
    let expandedContent = <View/>;
    if ( this.props.expanded ) {
      if ( this.props.expansion ) {
        descriptionStyle = { opacity: this.props.expansion.interpolate({ inputRange: [0, 1], outputRange: [1, 0] }) }
        expandedContentStyle = { opacity: this.props.expansion };
      }

      // Fill out the content based on what data's provided in the card
      if ( this.props.card.render ) {
        expandedContent = <Animated.View style={[styles.expandedContent, expandedContentStyle]}>{this.props.card.render}</Animated.View>;
      }
      else if ( this.props.card.content ) {
        console.log(this.props.card.content);
        expandedContent = <Animated.View style={[styles.expandedContent, expandedContentStyle]}>
                            <FlatProject content={this.props.card.content}
                                         personName={this.props.personName}
                                         description={this.props.card.description}
                                         width={cardWidth}
                                         mobile={this.props.mobile}/>
                          </Animated.View>;
      }
    }

    // Is there a button when we're expanded
    let button = <View/>;
    let buttonStyle = { opacity: this.props.expansion,
                        pointerEvents: this.props.expanded ? "auto" : "none" }
    if ( this.props.card.button ) {
      button = <ColorButton width={this.props.card.button.width}
                            text={this.state.watchingVideo ? "Stop" : this.props.card.button.text}
                            backgroundColor={ this.state.watchingVideo ? "red" : "black" }
                            height={42}
                            downScale={0.92}
                            onPress={() => {
                              this.onButtonPress();
                            }}/>


/*      button = <OutlineButton width={this.props.card.button.width}
                              text={this.props.card.button.text}
                              color="black"/>*/
    }

    // Fade it in as we expand
    let video = <View/>;
    let videoWrapperStyle = { opacity: this.state.videoOpacity };
    if ( this.props.expanded &&
         this.props.card.button &&
         this.props.card.button.video &&
         (this.state.watchingVideo || this.state.stoppingVideo)) {

      // Figure out the scale (no resize is an option)
      let scale = this.props.card.button.resolution == "original" ? "" : "/c_scale,h_720";
      video = <HigherVideo ref={video => this.video = video}
                           style={{ width: "100%", height: "auto" }}
                           useNativeControls
                           shouldPlay
                           isLooping={this.props.card.button.loop}
                           onEnd={() => { this.onVideoEnded(); }}
                           source={{ uri: `https://res.cloudinary.com/higher-design/video/upload${scale}/videos/${this.props.card.button.video}.mp4` }}/>
    }

    // Get the scroll style
    let scrollStyle = { borderTopLeftRadius: this.props.radius, borderTopRightRadius: this.props.radius };

    // People can specify a custom description
    let description = this.props.card.description;
    if ( this.props.personName ) {
      const nameCapitalized = this.props.personName.charAt(0).toUpperCase() + this.props.personName.slice(1)
      const customDescription = this.props.card[`descriptionFor${nameCapitalized}`];
      if ( customDescription ) {
        description = customDescription;
      }
    }

    // Return it now
    return (
      <View ref="container" style={{  }}>
      <TouchableWithoutFeedback
        onPressIn={ () => {
          this.setState({ pressed: true });
        }}
        onPressOut= { () => {
          this.setState({ pressed: false });
        }}
        onPress={ () => { this.handlePress(); } }>
          <Animated.View style={[styles.card, cardStyle]}>
              <Animated.View style={[styles.closeButton, { opacity: this.props.expanded ? this.props.expansion : 0 }]}>
                <CloseButton light={this.props.card.theme === "light"} thickness={0.17} size={1} onClose={() => { this.onClose(); }}/>
              </Animated.View>
              <Animated.ScrollView style={[styles.scroll, scrollStyle]} height={this.props.height}>
                <View style={styles.cardContent}>
                    <Animated.View style={[styles.posterWrapper, posterWrapperStyle]}>
                        <Animated.View style={[styles.poster, posterStyle]}>
                          <HigherImage style={[styles.image, imageStyle]}
                                       shoudLoadImage={this.props.expanded}
                                       thumbnailSource={{ uri: cardImage, cache: "force-cache" }}
                                       imageSource={{ uri: fullCardImage, cache: "force-cache" }}/>
                          <Animated.View style={[styles.videoWrapper, videoWrapperStyle]}>
                            {video}
                          </Animated.View>
                        </Animated.View>
                    </Animated.View>
                    <View style={styles.header}>
                      <Image style={{ width: (category ? category.imageWidth : 60) || 60,
                                      height: 42 }} source={{ uri: categoryImage }} />
                      <View style={styles.headerText}>
                        <Text style={styles.title}>{this.props.card.title}</Text>
                        <Text style={styles.subtitle}>{this.props.card.subtitle}</Text>
                      </View>
                      <Animated.View style={[styles.headerButton, buttonStyle]}>
                        {button}
                      </Animated.View>
                    </View>
                    <View style={styles.hairline}/>
                    <Animated.Text style={[styles.description, descriptionStyle]}>{description}</Animated.Text>
                    {expandedContent}
                </View>
              </Animated.ScrollView>
          </Animated.View>
        </TouchableWithoutFeedback>
      </View>
    );
  }
};

const childProps = (state, parentProps) => ({
  ...parentProps,
  expansion: parentProps.expansion || new Animated.Value(0),
  selectedCategoryName: state.category,
});

const childDispatch = (dispatch) => {
    return {
        chooseCategory: (category) => dispatch(chooseCategory(category)),
        chooseCard: (name, type, x, y, width, height) => dispatch(chooseCard(name, type, x, y, width, height)),
    };
};

export default connect(
  childProps,
  childDispatch
)(ProjectCard);

const styles = StyleSheet.create({
  card: {
    backgroundColor: "white",
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 20,
    elevation: 5,
  },
  cardContent: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  posterWrapper: {
    width: "100%",
    height: 170,
    overflow: "hidden",
    marginBottom: -10
  },
  poster: {
    width: "100%",
    height: 160,
  },
  image: {
    width: "100%",
    height: "100%",
  },
  videoWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  header: { flexDirection: "row", marginTop: 15, marginBottom: 15, marginLeft: 15, /*maxWidth: 515*/ },
  headerImage: { width: 60, height: 42 },
  headerText: { marginLeft: 10, justifyContent: "space-between", flex: 1 },
  headerButton: { alignSelf: "flex-end", marginLeft: 15, marginRight: 15 },
  title: { marginTop: 10, fontSize: 15, marginRight: 15, fontWeight: "600", fontFamily: 'System' },
  subtitle: { marginTop: 5, fontSize: 15, marginRight: 15, fontWeight: "300", fontFamily: 'System', whiteSpace: "nowrap" },
  hairline: { marginTop: 0, marginLeft: 15, marginRight: 15, height: 1, backgroundColor: "#E7E7E7"},
  description: { width: 290 - 30, marginTop: 15, marginLeft: 15, marginRight: 15, fontSize: 15, height: 100, fontWeight: "300", fontFamily: 'System' },
  expandedContent: { marginTop: -100 },
  entry: {
    height: 50,
    width: "100%",
    flexDirection: "row",
  },
  meter: {
    marginTop: 15,
    marginLeft: 15,
    marginRight: 15
  },
  bullet: {
    marginRight: 5,
  },
  content: {
    width: "100%",
    borderBottomWidth: 1,
    borderColor: "#F4F4F4",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  contentLast: {
    width: "100%",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  title: {
    fontSize: 16,
    fontWeight: "600",
    textAlign: "left",
    letterSpacing: -0.5,
    marginTop: 0,
    width: 230
  },
  extra: {
    fontSize: 14,
    color: "rgba(0,0,0,0.30)",
    letterSpacing: -0.25,
    marginTop: 17,
    textAlign: "right",
    marginRight: 25
  },
  closeButton: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 10
  },
  scroll: {
    overflow: "hidden",
  }
});

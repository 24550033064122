//https://res.cloudinary.com/higher-design/image/upload/v1552703321/people/pete.jpg
import React from 'react';
import {
  View,
  Text,
  FlatList,
  Dimensions,
  Platform,
  StyleSheet,
} from 'react-native';
import PEOPLE from '../../../constants/people';
import ConnectButton from '../../../components/buttons/ConnectButton';

export default class PersonTalkSection extends React.Component {

  // Track the window size
  state = Dimensions.get("window");

  // Update our dimensions now
  updateDimensions() {
    this.setState(Dimensions.get('window'));
  }

  componentWillMount() {
    if ( Platform.OS == "web" ) {
      window.addEventListener("resize", () => { this.updateDimensions() });
    }
    else {
      Dimensions.addEventListener("change", () => { this.updateDimensions() });
    }
  }

  componentWillUnmount() {
    if ( Platform.OS == "web" ) {
      window.removeEventListener("resize", () => { this.updateDimensions() });
    }
    else {
      Dimensions.removeEventListener("change", () => { this.updateDimensions() });
    }
  }

  render() {

    // Lookup the person
    const person = PEOPLE.index[this.props.person] || {};
    const talk = person.talk || {};

    // Is it the narrow version
    const narrow = this.state.width < 950;

    let buttons = <View/>;
    if ( narrow ) {
      buttons = <View style={styles.buttonsNarrow}>
        <View style={styles.button}><ConnectButton text={talk.button} email={talk.email} width={250} backgroundColor="#21D078"/></View>
      </View>;
    }
    else {
      buttons = <View style={styles.buttonsWide}>
        <View style={styles.button}><ConnectButton text={talk.button} email={talk.email} width={250} backgroundColor="#21D078"/></View>
      </View>;
    }

    // Show a header?
    let header = <View/>;
    if ( this.props.showHeader ) {
      header = <Text style={{ paddingTop: 15, paddingBottom: 15, textAlign: "center", fontSize: 28, fontWeight: "800", fontFamily: 'System'}}>TALK</Text>;
    }

    // Show our section
    return (
      <View style={{ backgroundColor: "#ffffff", textAlign: "center", paddingBottom: 15 }}>
        {header}
        <View style={styles.description}>
          {talk.paragraphs.map((paragraph) => {
            return <Text style={styles.paragraph}>{paragraph}</Text>
          })}
        </View>
        {buttons}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: "white",
    shadowColor: '#000',
    borderRadius:10,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 20,
    elevation: 5,
    paddingTop: 15,
    paddingBottom: 10,
    marginLeft: 20,
    marginRight: 20,
    paddingLeft: 25,
    paddingRight: 35,
  },
  cardContent: {
    width: "100%",
    height: "100%",
  },
  description: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 20,
    marginRight: 20,
    maxWidth: 500,
    textAlign: "left",
    alignSelf: "center",
  },
  paragraph: {
    fontSize: 16,
    fontWeight: "400",
    fontFamily: 'System',
    marginBottom: 15,
  },
  buttonsWide: {
    margin: "0 auto",
    alignSelf: "center",
    justifyContent: "center",
    textAlign: "center",
    marginTop: 5,
    marginBottom: 10,
  },
  buttonsNarrow: {
    margin: "0 auto",
    alignSelf: "center",
    justifyContent: "center",
    textAlign: "center",
    marginTop: 5,
    marginBottom: 10,
  },
  buttonSeparator: {
    width: 15,
  },
  button: {
    flex: 1,
  },
});

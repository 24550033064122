//https://res.cloudinary.com/higher-design/image/upload/v1552703321/people/pete.jpg
import React from 'react';
import {
  Animated,
  View,
  Image,
  Text,
} from 'react-native';
import HigherGradient from '../../../components/utility/HigherGradient'

export default class LogoSection extends React.Component {
  render() {

    // This is the image for the person
    const logoImage = `https://res.cloudinary.com/higher-design/image/upload/w_256/v1551630385/logos/higher.png`;

    // Show an optional description
    let description = <View/>;
    if ( this.props.showDescription ) {
      description = <Text style={{ marginTop: 15,
                                   marginBottom: 15,
                                   textAlign: "center",
                                   fontSize: 16,
                                   fontWeight: "400",
                                   fontFamily: 'System' }}>{this.props.description}</Text>
    }


    // Show our section
    return (
      <HigherGradient
         direction="to bottom"
         from="#ffffff"
         to="#ffffff">
        <Animated.View style={{ paddingTop: 20, textAlign: "center", opacity: this.props.opacity }}>
            <Image style={{ width: 100, height: 100, alignSelf: "center"}}
                   resizeMode="contain"
                   source={{ uri: logoImage }}
                   onPress={() => { if ( this.props.onPress ) { this.props.onPress(); }}}/>
            <Text style={{ marginTop: 15, fontSize: 36, fontWeight: "800", fontFamily: 'System', textAlign: 'center' }}>HIGHER.</Text>
            {description}
        </Animated.View>
      </HigherGradient>
    )
  }
}

export default {
    "kiwi": {
      era: "twelve",
      theme: "light",
      aspectRatio: 0.55,
      name: "kiwi",
      title: "Kiwi Research Platform",
      subtitle: "Typescript, Vue, Node",
      categories: ["app","web"],
      searchOrder: 1,
      description: "Interactive platform for experimental and manufacturing data. Operators use Kiwi for manufacturing tasks, and scientists use it to understand performance & root cause changes.",
      image: "twelve/kiwi4",
      content: [
        {text: "Kiwi is a \"wiki\" of cross-linked scientific knowledge, experiments, and manufactured products.   Operators use Kiwi for manufacturing tasks, and scientists utilize it to characterize system performance & root cause changes."},
        {text: "Kiwi is built using TypeScript, Vue.js, and Apollo Client on the front-end, and Node.js, JavaScript, GraphQL, Hasura, PostgreSQL, and TimescaleDB on the back-end.  It processes terabytes per month of scientific data, providing real-time monitoring, loss-less data compression, and detailed analysis capabilities."},
        {text: "Kiwi also features configurable checklists, customizable dashboards, digital twins of manufacturing lines and our fleet of electrolyzers, and integrations with QR scanners, label printers, and dozens of advanced scientific instruments."},
        {text: "An early version existed (developed by 2 engineers) when I joined Twelve, with a large backlog of feature requests.  Dozens of scientists each spent 1 hour / day manually adding experimental data, and wanted us to automate this work, and also add support for new kinds of data.  Within the first 6 months, we reduced daily manual work to <5 minutes for multiple teams of scientists."},
        {text: "Over the next two years, I expanded the team, recruiting 7 additional engineers, a product manager, and a designer.  I provided mentorship for the initial creator of Kiwi, who has now become an experienced manager.  We've significantly expanded the functionality of Kiwi for our users, and under the hood made the system extensible so that we could add features with greater velocity."}
      ]
    },
    "gecko": {
      era: "twelve",
      theme: "light",
      subtitle: "Python, MQTT",
      aspectRatio: 0.55,
      name: "gecko",
      title: "Gecko Automation Platform",
      categories: ["app"],
      searchOrder: 1,
      description: "Gecko powers our electrolysis test stands, coordinating dozens of subsystems to efficiently transform CO2. Operators control Gecko and visualize live data via an intuitive UI.",
      image: "twelve/gecko8",
      content: [
        {text: "Gecko powers our electrolysis test stands, coordinating dozens of subsystems to efficiently transform CO2. Operators control Gecko and visualize live data via an intuitive UI."},
        {text: "During my time at Twelve, I expanded our crew of Gecko engineers from 2 to 5, and provided guidance on technical direction, working to improve both the reliability and feature set of Gecko. We fully automated the process of collecting data and visualizing it in the cloud, removing 1 hour of daily manual work for each scientist."},
        {text: "Gecko is written in Python, and acts as a collection of processes that communicate with each other via MQTT.  Each process communicates with 1 hardware device, ensuring isolation in the case of a failure.  These processes can run across different computers and microcontrollers if necessary, and are designed to be fault tolerant and self-healing."},
        {text: "Gecko is a protocol polyglot, speaking ModBus, EthernetIP, and a dozen other custom protocols.  Data is collected from a wide variety of sensors, scientific instruments, and industrial equipment, and then streamed to our cloud API.  Data is retained locally in the case of internet outages, and streamed to the cloud when connectivity is restored."},
        {text: "Gecko also includes its own simple scripting language, allowing scientists to design new experiments that coordinate the activity of multiple connected devices."},
        {text: "Originally, Gecko included its own local UI based in QT.  After my first year at Twelve, I led the effort to replace this with a web-based UI that unified Gecko with Kiwi, and reduced the work of maintaining similar UI component libraries across two languages.  MQTT real-time messages received by the browser provided the real-time performance that local operators were used to.  Remote viewers receive updates within 2 seconds via GraphQL subscriptions, allowing for more comprehensive remote oversight of experiments."},
      ]
    },
    "opus-electrolyzer": {
      era: "twelve",
      theme: "light",
      aspectRatio: 0.55,
      name: "opus-electrolyzer",
      title: "Opus Electrolyzer",
      image: "twelve/opus",
      subtitle: "Container Size Hardware",
      categories: ["hardware"],
      searchOrder: 1,
      description: "Opus™ is a modular industrial-scale CO2 electrolyzer, controlled by PLC-based software. Real-time telemetry and hundreds of sensor readings are livestreamed to our Kiwi platform.",
      content: [
        {text: "Opus™ is a modular industrial-scale CO2 electrolyzer, controlled by PLC-based software. Real-time telemetry and hundreds of sensor readings are livestreamed to our Kiwi platform."},
        {text: "My controls team (part of the software department consisting of 3 controls engineers) contributed to software design, PLC and instrument selection, writing code, and commissioning for our 1st and 2nd generation systems.  We are currently working on the design of our 3rd generation system."},
        {text: "PLCs are programmable logic controllers often used for safe operation of large equipment.  We evaluated different PLC ecosystems and chose to leverage Rockwell Automation PLCs, PlantPAX, and FactoryTalk HMI to deliver a working system that is easy to maintain and expand.  In North America, this family of tools is the most common for industrial automation, making it easier to hire new controls engineers, and find support and replacement parts."},
      ]
    },
    "airplant": {
      era: "twelve",
      theme: "light",
      aspectRatio: 0.55,
      name: "airplant",
      title: "Airplant",
      image: "twelve/airplant4",
      subtitle: "Jet Fuel Plant",
      categories: ["hardware"],
      searchOrder: 1,
      description: "Our CO2 transformation plant (under construction in Washington) will make sustainable products out of air, including E-Jet fuel to reduce flight related CO2 emissions by 90%.",
      content: [
        {text: "Our CO2 transformation plant will make sustainable products out of air, including E-Jet fuel to reduce flight related CO2 emissions by 90%.  It will power sustainable flights for Alaska Airlines, and other airline customers. It is currently under construction in Moses Lake, Washington and is expected to be operational in 2025."},
        {text: "My controls team (a team within the software department of 3 controls engineers) helped design the software and network architecture of the plant, and selected controls equipment including PLC, DCS, and safety systems.  We also worked closely with external vendors on the detailed design of the plant and integration with our Opus electrolyzer and cloud systems for data analysis."},
      ]
    },
    "leaf-factory": {
      era: "twelve",
      theme: "light",
      aspectRatio: 0.55,
      name: "leaf-factory",
      image: "twelve/leaf-factory",
      title: "CO2 Membrane Factory",
      subtitle: "Production Facility",
      categories: ["hardware"],
      searchOrder: 1,
      description: "Robotic production line for mass producing our synthetic \"leaf\" for industrial photosynthesis.  Leverages ML, computer vision, and a digital twin for live production monitoring.",
      content: [
        {text: "Robotic production line for mass producing our synthetic \"leaf\" for industrial photosynthesis.  Leverages ML, computer vision, and a digital twin for live production monitoring."},
        {text: "I formed a new software team around this mission, mentored a star engineer into a project lead and team manager, and guided the software side of the project from conception through commissioning."},
        {text: "I worked closely with scientists to develop processes for in-line and off-line characterization of membranes, and also provided all UI/UX designs for human facing parts of the system."},
        {text: "The resulting system increased our production capacity by 10x, and now produces the majority of our CO2 electrolysis membranes."},
      ]
    },
    "leaf-lab": {
      era: "twelve",
      theme: "light",
      aspectRatio: 0.55,
      name: "leaf-lab",
      title: "Leaf Lab",
      subtitle: "Experimentation Platform",
      categories: ["hardware"],
      searchOrder: 1,
      description: "Lab for testing new synthetic leaf materials and processes.  I led the shift from handwritten notes and labels to a fully SW driven workflow, QR codes, and robotic analysis.",
      image: "twelve/leaf-lab3",
      content: [
        {text: "Our software powered lab for testing new materials, designs, and processes for our synthetic leaf." },
        {text: "I led the shift from paper worksheets and hand written labels to software checklists and automated measurements, QR codes printed on labels and glass slides, and robot arms to move samples between analysis equipment."},
        {text: "I interviewed scientists and lab technicians to understand their workflow, designed the UI/UX, and helping guide a team of 4 software engineers to build and deploy the system."},
        {text: "The resulting system saved 16 hours per week of human operator time, and helped us increase the accuracy and consistency of our experiments." },
        {text: "The image above is from a 2023 documentary called \"An Optimist's Guide to the Planet\" and shows 4 small CO2 membranes being sprayed with layers of catalyst ink for a series of experiments." }

      ]
    },
    "bonsai": {
      era: "twelve",
      theme: "light",
      aspectRatio: 0.55,
      name: "bonsai",
      title: "Electrolysis Test Stands",
      subtitle: "Experimentation Platform",
      categories: ["hardware"],
      searchOrder: 1,
      description: "Our fleet of server-rack sized CO2 electrolysis test stands allows us to run 50+ scientific experiments in parallel, helping us improve the performance of our core technology.",
      image: "twelve/bonsai-optimist-guide",
      content: [
        {text: "Our fleet of server-rack sized CO2 electrolysis test stands allows us to run 50+ scientific experiments in parallel, helping us improve the performance of our core technology."},
        {text: "My team writes the Python-based software (called Gecko) that powers all of our test stands.  Gecko coordinates dozens of subsystems to ensure efficient electrolysis (including sensors, pumps, valves, scientific instruments, and some microprocessors running CircuitPython), and streams data to our cloud platform for analysis."},
        {text: "We also write software to manage the test stands, including a web-based UI for operators to start and stop experiments, run pre-programmed sequences, and a data visualization tool for scientists to monitor the performance of the test stands in real time."},
        {text: "Our test stands have been in multiple documentaries, including An Optimist's Guide to the Planet (during a visit from Nikolaj Coster-Waldau), and Inside Bill's Brain (during a visit from Bill Gates)."},
      ]
    },
    "team-cards": {
      era: "twelve",
      theme: "light",
      aspectRatio: 0.55,
      name: "team-cards",
      title: "Team Cards",
      categories: ["management"],
      searchOrder: 1,
      description: "Description for Team Cards.",
      image: "twelve/team-cards3",
      content: [
        {text: "More details about the Team Cards."}
      ]
    },
    "twelve-hiring-exercises": {
      era: "twelve",
      theme: "light",
      aspectRatio: 0.55,
      name: "twelve-hiring-exercises",
      title: "Hiring Exercises",
      categories: ["management"],
      searchOrder: 1,
      description: "Description for Hiring Exercises.",
      image: "xxx/handheld-scanner-patent",
      content: [
        {text: "More details about the Hiring Exercises."}
      ]
    },
    "twelve-team-org": {
      era: "twelve",
      theme: "light",
      aspectRatio: 0.55,
      name: "twelve-team-org",
      title: "Team Org",
      categories: ["management"],
      searchOrder: 1,
      description: "Description for Team Org.",
      image: "xxx/handheld-scanner-patent",
      content: [
        {text: "More details about the Team Org."}
      ]
    },
    "twelve-team-rituals": {
      era: "twelve",
      theme: "light",
      aspectRatio: 0.55,
      name: "twelve-team-rituals",
      title: "Team Rituals",
      categories: ["management"],
      searchOrder: 1,
      description: "Description for Team Rituals.",
      image: "xxx/handheld-scanner-patent",
      content: [
        {text: "More details about the Team Rituals."}
      ]
    },
    "career-conversations-framework": {
      era: "twelve",
      theme: "light",
      aspectRatio: 0.55,
      name: "career-conversations-framework",
      title: "Career Convo Framework",
      categories: ["management"],
      searchOrder: 1,
      description: "Description for Career Conversations Framework.",
      image: "xxx/handheld-scanner-patent",
      content: [
        {text: "More details about the Career Conversations Framework."}
      ]
    },
    "twelve-design-system": {
      era: "twelve",
      theme: "light",
      aspectRatio: 0.55,
      name: "twelve-design-system",
      title: "Twelve Design System",
      subtitle: "Storybook, Figma",
      categories: ["web"],
      searchOrder: 1,
      description: "Provides unique components for innovative processes, and general UI for scientific visualization, HW control, efficient manufacturing experiences, and error-free data entry.",
      image: "twelve/storybook8",
      content: [
        {text: "Our design system provides unique components for innovative processes, and general UI for scientific visualization, HW control, efficient manufacturing experiences, and error-free data entry."},
        {text: "Seeing the impact of design systems at past organizations, I began working with engineers on the team to create a consistent visual language optimized for our use cases."},
        {text: "I recruited a senior front-end engineer and a senior designer to help develop our design system, and mentor others in using it to build out new experiences."},
        {text: "Our design system is documented in both Figma and Storybook.  Today, the design system is applied to all new features, and 100+ pages in Kiwi use design system components.  Our team continues to refine the design system to encompass new use cases, and learnings from customers."},
      ]
    },
    "twelve-hasura": {
      era: "twelve",
      theme: "light",
      aspectRatio: 0.55,
      name: "twelve-hasura",
      title: "Kiwi GraphQL API",
      subtitle: "Hasura Backend",
      categories: ["cloud"],
      searchOrder: 1,
      description: "Our GraphQL API, powered by Hasura, accelerates development of new user experiences and provides access to the rich trove of manufacturing and scientific data in Kiwi.",
      image: "twelve/hasura-graphql8",
      content: [
        {text: "Our GraphQL API, powered by Hasura, accelerates development of new user experiences and provides access to the rich trove of manufacturing and scientific data in Kiwi."},
        {text: "During my first year at Twelve, I led the switch from manually maintained REST based API's (with hand-coded SQL) to dynamically generated GraphQL API's using Hasura.  This reduced back-end development time of new features by 3x, and also improved front-end development time and performance."},
        {text: "Hasura is a GraphQL engine that sits on top of a PostgreSQL database, and provides a real-time API for reading and writing data.  It also provides a role-based access control system, and a way to extend the API with custom code."},
        {text: "I'd seen a similiar improvement in development time and performance at my prior company and began this transition by educating the team on its benefits, getting buy-in, and building expertise with the technology first in our senior engineers and then across the team."},
      ]
    },
    "twelve-kubernetes": {
      era: "twelve",
      theme: "light",
      aspectRatio: 0.55,
      name: "twelve-kubernetes",
      title: "Containerized Kiwi",
      subtitle: "Kubernetes, Docker",
      categories: ["cloud"],
      searchOrder: 1,
      description: "Kubernetes orchestrates our Kiwi cloud platform, with containers for Node, Hasura, and PostgreSQL / Timescale. Rapidly spins up new versions for development and testing.",
      image: "twelve/containerized-kiwi15",
      content: [
        {text: "Kubernetes orchestrates our Kiwi cloud platform, with containers for Node, Hasura, and PostgreSQL / Timescale. Rapidly spins up new versions for development and testing."},
        {text: "Setting up a new system for development or testing used to take 1-2 days, and a significant amount of troubleshooting.  The move to Kubernetes automated the setup of our development and production systems."},
        {text: "As I was the only person on the team with Kubernetes experience, I wrote the scripts to set up our Kubernetes cluster, and then trained the team on how to use it.  We now have several senior engineers who are knowledgeable on Kubernetes, who are able to maintain and extend the system."},
      ]
    },
    "timescale-db": {
      era: "twelve",
      theme: "light",
      aspectRatio: 0.55,
      name: "timescale-db",
      title: "Temporal Vault",
      subtitle: "Timescale DB, Node",
      categories: ["cloud"],
      searchOrder: 1,
      description: "Time series DB and API processes\n8 TB of scientific data / year from electrolysis test stands. Provides real-time monitoring, loss-less data compression, and detailed analysis.",
      image: "twelve/timescale1",
      content: [
        {text: "Time series API and DB processes 8 TB of scientific data / year from electrolysis test stands. Provides real-time monitoring, loss-less data compression, and detailed analysis capabilities."},
        {text: "Data flows from a fleet of 50+ test stations to our Node JS based API, with each test station individually generating hundreds of readings per second (from dozens of hardware instruments).  Data is stored using PostgreSQL DB, and the Timescale DB extension for high performance time series data storage, retrieval, compression, and analysis. "},
        {text: "My role was helping select the technology, guiding the system architecture and team implementing it, and personally setting up our Timescale / Postgres DB instances (and streaming point in time backups) in Google Cloud with Kubernetes and Docker.  I also worked to optimize the performance of the database to minimize server load and ensure a responsive user experience."},
        {text: "Within the test stands, data is collected from a wide variety of sensors, scientific instruments, and industrial equipment, to a dedicated PC running MQTT and Python, and then streamed to our cloud API.  Data is retained locally in the case of internet outages, and streamed to the cloud when connectivity is restored."},
      ]
    }
  }
  
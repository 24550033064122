//             ["nextengine-scanner", "scanstudio", "nextwiki", "nextengine-website", "nextengine-comparo", "nextengine-order-system", "rapidworks", "nextengine-licensing"],

export default {
  "model3": {
    name: "model3",
    image: "tesla/model3",
    theme: "light",
    aspectRatio: 0.55,
    title: "Tesla Model 3",
    subtitle: "Hardware",
    /*button: { text: "Demo", video: "nextengine/jay-leno-demo" },*/
    //button: { text: "View", url: "https://1lss.com/" },
    categories: ["hardware"],
    searchOrder: 4,
    description: "The Model 3 features advanced battery management and charging systems, which communicate with firmware throughout the car and undergo rigorous safety testing.",
    //render: () => { return <TextBladeProject/> },
    content: [
      { description: true },
    ],
  },




};

export default {
  "portfolio": {
    era: "personal",
    name: "portfolio",
    image: "personal/portfolio-in-ipad",
    theme: "light",
    aspectRatio: 0.55,
    searchOrder: 10,
    title: "Petefol.io",
    subtitle: "App, Web",
    categories: ["app", "web"],
    description: "This portfolio is 100% custom built.  It's powered by a single React Native codebase that compiles to iOS, Android, and Web, delivering smooth native performance everywhere.",
    content: [
      { description: true },
      { text: "I've long dreamed of a singular home for all my work, and also of creating an uncompromised yet fully multi-platform experience with a single codebase.  This project fulfills both dreams." },
      { text: "The playful and colorful design helps visitors explore and understand the unique multi-disciplinary nature of my work.  It perfects the card-based user interface that I began exploring with UpGift, and was built from the ground up to support motion." },
      { text: "As motion design has become standard table stakes, the task of the designer and front end engineer has become more mechanical and physical in nature.  We must design and build simulated machines that move and unfold in a realistic way.  Animation and motion are not add-ons.  Rather, they require a rethinking of the very bones and object oriented layout of our front-end code." },
    ],
  },
  "teamfolio": {
    era: "personal",
    name: "teamfolio",
    image: "personal/portfolio-in-ipad",
    theme: "light",
    aspectRatio: 0.55,
    searchOrder: 10,
    title: "Teamfolio",
    subtitle: "App, Web",
    categories: ["app", "web"],
    description: "This portfolio is 100% custom built.  It's powered by a single React Native codebase that compiles to iOS, Android, and Web, delivering smooth native performance everywhere.",
    content: [
      { description: true },
      { text: "The playful and colorful design helps visitors explore and understand the unique multi-disciplinary nature of our work.  It perfects the card-based user interface explored with UpGift, and was built from the ground up to support motion." },
      { text: "As motion design has become standard table stakes, the task of the designer and front end engineer has become more mechanical and physical in nature.  We must design and build simulated machines that move and unfold in a realistic way.  Animation and motion are not add-ons.  Rather, they require a rethinking of the very bones and object oriented layout of our front-end code." },
    ],
  },
  "retro-rabbit-run": {
    era: "personal",
    name: "retro-rabbit-run",
    image: "personal/retro-rabbit-laptop-detailed-dark",
    theme: "light",
    aspectRatio: 0.55,
    searchOrder: 10,
    title: "Retro Rabbit Run",
    button: { text: "Play Game", url: "https://retrorabbit.games" },
    subtitle: "App",
    categories: ["app"],
    description: "Hop into learning Morse code with a fun side-scroller generated from classic literature. Runs on desktop, iPad, and mobile, powered by a custom built tile-based game engine.",
    content: [
      { text: "Retro rabbit is on the run, in this fun side-scroller that teaches morse code. The game world is automatically generated based on text from Retro's favorite public domain works about rabbits, which you get to read as you progress through the level." },
      { text: "Tap the screen or any key quickly to make Retro hop (dot), or hold the screen for a longer jump (dash). If you turn on your audio, you'll hear morse code being played as Retro hops. Each letter of the public domain story is translated into Morse code, which guides Retro's path in avoiding obstacles and catching carrots."},
      { link: "🧑🏻‍💻 View Code on GitHub", url: "https://github.com/delaurentis/retro-rabbit-run" },
      { text: "The game is designed to be played on desktop, iPad, and mobile phones.  It runs on a simple sprite engine I built in TypeScript and Vue.js, and features tile-based level generation. The engine can be customized with a different hero, or different obstacles, enemies, or power ups.  Pixel accurate collision detection leverages an off-screen canvas, and adapts automatically to the artwork, including any animations." },
      { photo: "content/personal/retro-rabbit.gif", marginLeft: 10, maxWidth: 100, resolution: 132, aspect: 132 / 108 },
      { space: 20 },
      { text: "The artwork was created by my 15 year old daughter, and stories are included by Beatrix Potter, Margery Williams, and Lewis Carroll." },
    ],
  },
  "retro-rabbit-multiply": {
    era: "personal",
    name: "retro-rabbit-multiply",
    image: "personal/rabbits-multiply",
    theme: "light",
    aspectRatio: 0.55,
    searchOrder: 10,
    title: "Multiplication Game",
    button: { text: "Play Game", url: "https://multiply.retrorabbit.games" },
    subtitle: "App",
    categories: ["app"],
    description: "Teach Retro Rabbit how to multiply, and earn carrots along the way.  I developed this browser based game to teach my kids both multiplication tables and how to code in React.",
    content: [
      { description: true },
      { link: "🧑🏻‍💻 View Code on GitHub", url: "https://github.com/delaurentis/retro-rabbit-multiply" },
      { text: "Retro Rabbit Multiply is game written in React, optionally backed by a Node.js server. We made this game to help Emma (age 10) learn her multiplication tables, and for Sofia (age 12) and Jack (age 8) to learn to code." },
      { text: "The game can run fully in the front-end (for easy deployment) or with an optional back-end that keeps track of kids' answers in the cloud (when played from multiple devices). Either locally, or in the cloud, answers are stored in a database which can be queried to understand common patterns in student comprehension." },
    ],
  },
  "planner": {
    era: "personal",
    name: "planner",
    image: "personal/planner-tasks13",
    theme: "light",
    aspectRatio: 0.55,
    searchOrder: 10,
    title: "Sprint Planner App",
    button: { text: "View Code", url: "https://github.com/delaurentis/planner" },
    subtitle: "Typescript, React",
    categories: ["app"],
    description: "Teams can create, estimate, and manage tickets faster with this custom GitLab client.  Over the last 4 years, my teams have used Planner to manage 100 sprints and 4,000 tickets.",
    content: [
      { description: true },
      { text: "Planner is fully open source and has been developed in my free time on weekends and evenings.  I've used it to keep my coding skills razor sharp, and help my teams be more effective." },
      { text: "Powerful shortcuts allow an optional keyboard-driven UX reminiscient of vi or Superhuman." },
      { text: "Additional features are under development now for roadmap and epic planning, which you can see in the design below.  I'm also extending Planner to support GitHub, Asana, and Jira tickets." },
      { photo: "projects/personal/planner-roadmap8.jpg", aspect: 880 / 485, resolution: 2000, marginRight: 10 },
    ],
  },

};
